<div class="root-container container-fluid footer-container">
  <div class="row footer">
    <div class="col-md-2 offset-md-2 h-75 bosch-navbar">
      <img class="bosch-logo" src="assets/bosch-logo-5.png" alt="bosch navigation bar" />
    </div>
    <div class="offset-md-5 col-1 h-75">
      <ul>
        <li><img class="img-scroll" src="assets/Component 53 – 1.svg" (click)="onActivate($event)" /></li>
        <li>&nbsp;</li>
        <li>&nbsp;</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <img class="footer-img img-fluid" src="assets/bosch-dashboard_navbar.png" alt="bosch navigation bar" />
  </div>
</div>