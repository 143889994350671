import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class AuthService {

  constructor(
    private keycloakAngular: KeycloakService
  ) {
    this.getUserAccessToken();
  }

  public getUserAccessToken() {
    let token: string;
    this.keycloakAngular.getToken()
      .then(res => {
        token = res;
        localStorage.setItem('userHash', token);
      })
      .catch(error => {
        console.log(error);
      });
  }

  public getUserProfile() {
    if (this.isLogged()) {
      return this.keycloakAngular.loadUserProfile();
    }
  }

  public getUserName() {
    if (this.isLogged()) {
      return this.keycloakAngular.getUsername();
    }
  }

  public getUserRoles(): Array<string> {
    return this.keycloakAngular.getUserRoles();
  }

  public isLogged(): Promise<boolean> {
    return this.keycloakAngular.isLoggedIn();
  }

  public logOut() {
    this.keycloakAngular.logout();
    localStorage.removeItem('status');
    localStorage.removeItem('userHash');
  }

}
