import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '../shared/app.api';
import { debounceTime, map, catchError, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { downloadFileFromHttp } from '../shared/utils/file-handler';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  
  private childUpdate = new Subject<any>();
  changeEmitted$ = this.childUpdate.asObservable();
  emitChange() {
      this.childUpdate.next();
  }

  getInvoice(fatura: string) {
    return this.http.get(`${API}/invoices/${fatura}`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  getComments(invoiceId: string) {
    return this.http.get(`${API}/invoice/${invoiceId}/comment`)
    .pipe(
      debounceTime(1000),
      map((response: any) => response.data),
    )
  }

  newComments(invoiceId: string, data) {
    return this.http.post(`${API}/invoice/${invoiceId}/comment`, data)
    .pipe(
      debounceTime(1000),
      map((response: any) => response),
    )
  }

  updateComments(invoiceId: string,commentId: string, data) {
    return this.http.post(`${API}/invoice/${invoiceId}/comment/${commentId}`, data)
    .pipe(
      debounceTime(1000),
      map((response: any) => response),
    )
  }

  deleteComments(invoiceId: string,commentId: string) {
    return this.http.post(`${API}/invoice/${invoiceId}/deleteComment/${commentId}`, {})
    .pipe(
      debounceTime(1000),
      map((response: any) => response),
    )
  }

  getContract(contract: string) {
    return this.http.get(`${API}/contracts/${contract}`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  getContacts(idContrato: string) {
    return this.http.get(`${API}/contracts/${idContrato}/contacts`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  getServices(idContrato: string) {
    return this.http.get(`${API}/contract/${idContrato}/service`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  getPens(idFarm: string) {
    return this.http.get(`${API}/farm/${idFarm}/pens`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  changeStatus(invoiceId: string, statusTo: string, stateInvoiceNumber?: string){
    let body = {
      invoiceId: invoiceId,
      statusTo: statusTo
    };
    if(stateInvoiceNumber){
      body['stateInvoiceNumber'] = stateInvoiceNumber;
    }
    return this.http.post(`${API}/invoices/changeStatus`, body)
      .pipe(
        debounceTime(500),
        map(response => response),
      )

  }

  regenerateInvoice(invoiceId: string) {
    return this.http.get(`${API}/invoices/${invoiceId}/regenerate`)
      .pipe(
        debounceTime(1000),
        map((response: any) => response.data),
      )
  }

  saveContact(idContract: string, lstContacts: any[]){
    let contacts: any[] = [];

    lstContacts.map((item, index) => {
      let channels: any[] = [];

      channels.push({
        content: item.phone,
        type: "telefone"
      })

      channels.push({
        content: item.cellphone,
        type: "celular"
      })

      channels.push({
        content: item.email,
        type: "email"
      })

      channels.concat(item.lstAditionalChannel)
      contacts.push({
        name: item.name,
        contactChannels: channels,
        notes: item.notes,
      })
    });

    let body = {
      contacts: contacts
    };
    return this.http.post(`${API}/contracts/${idContract}/contacts`, body)
      .pipe(
        debounceTime(500),
        map(response => response),
      )

  }

  addService(idContract: string, service: any){
    return this.http.post(`${API}/contract/${idContract}/service`, service)
      .pipe(
        debounceTime(500),
        map(response => response),
      )

  }

  updateService(idContract: string, service: any){
    return this.http.post(`${API}/contract/${idContract}/service/${service._id}`, service)
      .pipe(
        debounceTime(500),
        map(response => response),
      )

  }

  export(id: string, farm: string, refDate: string, invoiceNumber: number) {
    return this.http.get(`${API}/invoices/exportToExcel/${id}`,{responseType: 'blob' as 'json'}).subscribe(
      (response: any) =>{
        downloadFileFromHttp(`${farm} - ${invoiceNumber} - ${refDate}.xlsx`, response)
      }
    )
  }

  exportToPDF(id: string, farm: string, refDate: string, invoiceNumber: number) {
    return this.http.get(`${API}/invoices/exportToPdf/${id}`,{responseType: 'blob' as 'json'}).subscribe(
      (response: any) =>{
        downloadFileFromHttp(`${farm} - ${invoiceNumber} - ${refDate}.pdf`, response)
      }
    )
  }

  updateInvoiceChargedValue(idInvoice: string, chargedValue: any, chargedValueComment: any){
    const body = {
      chargedValue: String(chargedValue),
      chargedValueComment
    };
    return this.http.post(`${API}/invoice/${idInvoice}/chargedValue`, body)
      .pipe(
        debounceTime(500),
        map(response => response),
      )

  }

}
