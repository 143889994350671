import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingService } from "../shared/loading/loading.service";
import { LoadingComponent } from "../shared/loading/loading.component";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  @ViewChild(LoadingComponent, {}) loadingComponent: LoadingComponent;
  tabIndex = 0;

  loadingBehaviorSubject: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private updateRef: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.changeOnLoadIfAlreadyInContracts(this.router.url);
    this.watchLoading();
  }

  changeOnLoadIfAlreadyInContracts(url: string) {
    if (url.includes("contracts")) {
      this.tabIndex = 1;
      this.updateRef.detectChanges();
    }
  }

  tabChange(tabNumber) {
    switch (tabNumber) {
      case 0:
        return this.router.navigate(["home/bills"]);

      case 1:
        return this.router.navigate(["home/contracts"]);
    }
  }

  watchLoading() {
    this.loadingService.billsAndContractsLoading.subscribe(x => {
      if (x === true) {
        this.loadingComponent.enabled = true;
      } else {
        this.loadingComponent.enabled = false;
      }
    });
  }
}
