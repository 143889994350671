import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseTableComponent } from "./base-table/base-table.component";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatOptionModule, MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { EnumPipe } from "./pipes/enum-pipe/enum.pipe";
import { MatSortModule } from '@angular/material/sort';
import { OverviewInvoiceComponent } from '../invoice/overview-invoice/overview-invoice.component';
import { BaseTableInvoiceComponent } from './base-table-invoice/base-table-invoice.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../services/auth.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { BaseTableContractComponent } from './base-table-contract/base-table-contract.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  declarations: [
    BaseTableComponent, 
    EnumPipe, 
    OverviewInvoiceComponent, 
    BaseTableInvoiceComponent, 
    BaseTableContractComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    LayoutModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatTableModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSortModule,
    MatTooltipModule,
    MatGridListModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CurrencyMaskModule
  ],
  exports: [
    EnumPipe,
    BaseTableComponent, 
    MatIconModule, 
    MatGridListModule, 
    MatExpansionModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    OverviewInvoiceComponent, 
    BaseTableInvoiceComponent,
    ReactiveFormsModule,
    BaseTableContractComponent,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatChipsModule,
    CurrencyMaskModule,
    MatInputModule
  ],
  providers: [AuthService],
})
export class SharedModule {}
