import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-overview-contract',
  templateUrl: './overview-contract.component.html',
  styleUrls: ['./overview-contract.component.scss']
})
export class OverviewContractComponent implements OnInit {

  constructor(
    private _api: ApiService,
    private route: ActivatedRoute,
    private _invoice: InvoiceService
    ) { }

  contrato: string;
  lastPeriod: string;
  qtdAnimals: number;
  service: string;
  vlrFaturado: number;
  dtLastBill: string;
  fazenda: string;
  dtCriacao: string;
  faturasAberto: string[];
  cpfcnpj: string;
  dtInicio: string;
  linkedSystems: string;
  tipo: string;
  dtVencimento: string;
  vigencia: string;
  dtProximo: string;
  hasAFaturar: boolean = false;
  scrollToContacts: boolean = false;
  stateRegistration: string;
  queryParam: string;

  ngOnInit(): void {
    this.queryParam = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe(params => {
      this.scrollToContacts = params['scrollToContacts'];
  });    
    this.loadContractComponent(this.queryParam);  
    this.listenToPenChanges();
  }

  ngAfterViewInit(): void{
    console.log(this.scrollToContacts);
    if(this.scrollToContacts){
      window.scrollTo(0, 600);
    }
  }

  loadContractComponent(queryParam: string) {
    return this._api.getContract(queryParam)
    .subscribe(response => {
      // console.log(response);
      
      this._invoice.setIdFarm(response.farmId);
      this._invoice.setNumContract(response.code);

      let dateStart = new Date(response.startingDate);
      let dtInicio = !dateStart ? "" : (dateStart.getDate()).toString().padStart(2, '0') + "/" + (dateStart.getMonth() + 1).toString().padStart(2, '0') + "/" + dateStart.getFullYear();
      let dateEnd = new Date(response.endingDate);
      let dtFim = !dateEnd ? "" : (dateEnd.getDate()).toString().padStart(2, '0') + "/" + (dateEnd.getMonth() + 1).toString().padStart(2, '0') + "/" + dateEnd.getFullYear();

      this.contrato = response.code;
      this.lastPeriod = response.lastGeneratedInvoiceClosingDate;
      this.qtdAnimals = response.NumberOfMonitoredAnimals;
      this.service = response.serviceTypes;
      this.vlrFaturado = response.invoicedValue;
      this.dtLastBill = response.lastInvoicedDate;
      this.fazenda = response.farmName;
      this.dtCriacao = response.createdAt;

      if(response.openInvoices.length > 0) this.faturasAberto = response.openInvoices;

      this.cpfcnpj = response.farmDocument;
      this.dtInicio = response.startingDate;
      this.linkedSystems = response.attachedPens;
      this.tipo = response.type;
      this.dtVencimento = response.endingDate;
      this.vigencia = "De " + dtInicio + " até " + dtFim;
      this.dtProximo = response.nextInvoiceDate;
      this.hasAFaturar = response.invoicesToBillAlert;
      this.stateRegistration = response.stateRegistration;
    });

  }

  listenToPenChanges(){
    const penListener =this._invoice.getpenChange();
    penListener.subscribe(x => {
      console.log('load contract');
      this.loadContractComponent(this.queryParam);
    });
  }

}
