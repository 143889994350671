
<div class="root-container sub-container">
    <div class="col-xl-8 offset-xl-2 col-10 offset-1">
        <section id="row-overview">
            <div class="row mt-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/home/contracts">Contratos</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Registro de Contrato</li>
                    </ol>
                </nav>
            </div>
        </section>
            <div class="row">
                <div class="col col-md-10 vg-label">
                    <label class="subtitle">Cadastro de Contrato</label>
                </div>
            </div>
            <form class="form-area" [formGroup]="formGroup" #ngForm="ngForm">
            <div *ngIf="contractRegisterFirstScreen" class="col col-12 contract-info-form">
                <div class="row">
                    <div class="row pl-3">
                        <label class="blue contract-header"><strong>INFORMAÇÕES GERAIS DO CONTRATO</strong></label>
                    </div>
                </div>                
                
                <div class="row">
                    <div class="col col-12">
                        <span><b>Nome da Fazenda*</b></span>
                        <input class="form-control" placeholder="Nome" type="text"  formControlName="farm" [matAutocomplete]="auto"
                        [ngClass]="{'error': (this.formGroup.controls.farm.touched || this.formGroup.controls.farm.dirty || ngForm.submitted) && !this.formGroup.controls.farm.valid}">
                                                    
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setFarmId()">
                           <mat-option *ngFor="let farm of filteredOptions | async" [value]="farm.name">{{farm.name}}</mat-option>
                        </mat-autocomplete>                        
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col col-6">
                        <span><b>CPF/CNPJ*</b></span>
                        <input class="form-control" formControlName="farmDocument" placeholder="CPF/CNPJ da fazenda" 
                        [ngClass]="{'error': (this.formGroup.controls.farmDocument.touched || this.formGroup.controls.farmDocument.dirty || ngForm.submitted) && !this.formGroup.controls.farmDocument.valid}"/>
                    </div>
                    <div class="col col-6">
                        <span><b>Inscrição Estadual</b></span>
                        <input class="form-control" formControlName="stateRegistration" placeholder="Inscrição Estadual" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col col-12">
                        <span><b>Nome do Cliente*</b></span>
                        <input class="form-control" formControlName="clientName" placeholder="Nome do Cliente" 
                        [ngClass]="{'error': (this.formGroup.controls.clientName.touched || this.formGroup.controls.clientName.dirty || ngForm.submitted) && !this.formGroup.controls.clientName.valid}"/>
                    </div>
                </div>                           
                <div class="row mt-3">                  
                    <div class="col col-5">
                        <p class="mb-1"><span><b>Data de Início*</b></span></p>
                        <input class="form-control" [matDatepicker]="picker1" formControlName="startingDate"
                                placeholder="Selecionar data" disabled 
                                [ngClass]="{'error': (this.formGroup.controls.startingDate.touched || this.formGroup.controls.startingDate.dirty || ngForm.submitted) && !this.formGroup.controls.startingDate.valid}"/>
                    </div>
                    <div class="col col-1 datepicker-form">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                    </div>                         
                    <div class="col col-5">
                        <p class="mb-1"><span><b>Data de Término*</b></span></p>
                        <input class="form-control" [matDatepicker]="picker2" formControlName="endingDate"
                            placeholder="Selecionar data" disabled 
                            [ngClass]="{'error': (this.formGroup.controls.endingDate.touched || this.formGroup.controls.endingDate.dirty || ngForm.submitted) && !this.formGroup.controls.endingDate.valid}"/>
                    </div>
                    <div class="col col-1 datepicker-form">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 disabled="false"></mat-datepicker> 
                    </div>                      
                </div>                 
            </div>
           <app-contact (contactsUpdate)="updateContactList($event)" [isContractRegisterParent]="true" [triggerEmptyList]="triggerEmptyContactList" *ngIf="contractRegisterFirstScreen"></app-contact>
           <app-service [paramFarmId]="farmId" [isContractRegisterParent]="true" (servicesUpdate)="updateServicesList($event)" *ngIf="!contractRegisterFirstScreen"></app-service>
            <div class="row mt-3 mb-5">
                <div class="col-12 button-area">
                    <a href="/home/contracts" class="cancel-button" *ngIf="contractRegisterFirstScreen">
                        Cancelar
                    </a>
                    <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base" (click)="goToSecondScreen()" *ngIf="contractRegisterFirstScreen">
                        <mat-icon class="icon" svgIcon="ic-new-doc"></mat-icon>
                        Próximo Passo
                    </button>   
                    <a class="go-back-button" (click)="goToFirstScreen()" *ngIf="!contractRegisterFirstScreen">
                        Voltar
                    </a>   
                    <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base" (click)="submit()" *ngIf="!contractRegisterFirstScreen" >
                        <mat-icon class="icon" svgIcon="ic-new-doc"></mat-icon>
                        Cadastrar Contrato
                    </button>                                                      
                </div>    
            </div>     
        </form>           
    </div>

</div>