import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SvgIconService } from 'src/app/services/svg-icon.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SlideInOutAnimation } from 'src/app/shared/animation';
import { ApiService } from 'src/app/services/api.service';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [SlideInOutAnimation]
})
export class ContactComponent implements OnInit {

  @Input()
  isContractRegisterParent: boolean;

  @Input()
  triggerEmptyList: boolean;

  @Output()
  contactsUpdate = new EventEmitter();

  constructor(
    private iconService: SvgIconService,
    private _api: ApiService,
    private _invoice: InvoiceService,
  ) { }

  editionMode: string = "hidden";
  animationState = 'out';
  lstContacts: any[] = [];
  lstAditionalChannel: any[] = [];
  idContract: string;
  expanded: boolean = false;
  lblChannel: string = "Adicionar contato";
  lblbuton: string = "Adicionar Contato";
  lblPlaceholder: string = "Digite um valor";
  phonePattern: string = "/^\([1-9]{2}\)[0-9]{4,5}-[0-9]{4}$/";
  erroPhone: boolean = false;
  erroCellPhone: boolean = false;
  erroEmail: boolean = false;
  noName: boolean = false;
  noChannel: boolean = false;
  get f() { return this.formGroup.controls; }

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl(""),
    name: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.minLength(10)),
    cellphone: new FormControl("", Validators.minLength(11)),
    email: new FormControl("", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
    contactType: new FormControl(""),
    contactValue: new FormControl(""),
    lstContacts: new FormControl(""),
    notes: new FormControl(""),
  });

  ngOnInit(): void {
    if(!this.isContractRegisterParent){
      this._invoice.idContract.subscribe(b => {
        this.idContract = b;
      });      
      this._api.getContacts(this.idContract)
      .subscribe((response: any) => {

        response.map((item, index) => {
          let contact = {
            _id: item._id,
            name: item.name,
            phone: "",
            cellphone: "",
            email: "",
            lstAditionalChannel: [],
            notes: item.notes,
          }

          let channels = [];
          item.contactChannels.map((item2, index2) => {
            channels.push({
              type: item2.type,
              content: item2.content
            })
          });

          let phone = channels.find(b => b.type == "telefone");
          let cellphone = channels.find(b => b.type == "celular");
          let email = channels.find(b => b.type == "email");

          contact.phone = phone ? phone.content : "";
          contact.cellphone = cellphone ? cellphone.content : "";
          contact.email = email ? email.content : "";

          let iphone = channels.indexOf(phone);
          channels.splice(iphone, 1);
          let icellphone = channels.indexOf(cellphone);
          channels.splice(icellphone, 1);
          let iemail = channels.indexOf(phone);
          channels.splice(iemail, 1);

          channels.map((item2, index2) => {
            contact.lstAditionalChannel.push({
              type: item2.type,
              content: item2.content
            })
          })

          this.lstContacts.push({
            _id: contact._id,
            name: contact.name,
            phone: contact.phone,
            cellphone: contact.cellphone,
            email: contact.email,
            lstAditionalChannel: contact.lstAditionalChannel,
            notes: contact.notes,
          });

        })

      });
    }
  }

  ngOnChanges(){
    this.lstContacts = [];
    console.log('change');
  }

  edit(contact: any, el: HTMLElement) {
    this.toggleEditionMode("visible");
    this.expanded = true;
    this.formGroup.patchValue({
      _id: contact._id,
      name: contact.name,
      phone: contact.phone,
      cellphone: contact.cellphone,
      email: contact.email,
      contactType: "",
      contactValue: "",
      lstContacts: contact.lstAditionalChannel,
      notes: contact.notes,
    });

    this.lblbuton = "Atualizar Contato";
    this.lblChannel = "Editar canal de Contato";
    // if(contact.lstAditionalChannel.lenght > 0) this.toggleShowDiv("newchanel");
    el.scrollIntoView({ behavior: "smooth" });
  }

  toggleShowDiv(divName: string) {
    if (divName === 'newchanel') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  addChanel() {
    if (!this.formGroup.get("contactType").value || !this.formGroup.get("contactValue").value) return;

    let icon;
    switch (this.formGroup.get("contactType").value) {
      case "email":
        icon = "../../../assets/Component 40 – 1.svg";
        break;
      case "celular": case "telefone":
        icon = "../../../assets/Component 39 – 1.svg";
        break;
    }

    this.lstAditionalChannel.push({
      type: this.formGroup.get("contactType").value,
      content: this.formGroup.get("contactValue").value,
      icon: icon
    })
  }

  addContact() {
    this.toggleEditionMode("hidden");
    let _id = this.formGroup.get("_id").value
    let name = this.formGroup.get("name").value
    let phone = this.formGroup.get("phone").value
    let cellphone = this.formGroup.get("cellphone").value
    let email = this.formGroup.get("email").value
    let notes = this.formGroup.get("notes").value

    if (this.f.name.errors) {
      this.noName = true;
      return;
    } else this.noName = false;

    if (!phone && !cellphone && !email) {
      this.noChannel = true;
      return;
    } else this.noChannel = false;

    if (_id) {
      let oldContact = this.lstContacts.findIndex(b => b._id === _id);
      this.lstContacts.splice(oldContact, 1);
    }

    this.lstContacts.push({
      _id: _id,
      name: name,
      phone: phone,
      cellphone: cellphone,
      email: email,
      lstAditionalChannel: this.lstAditionalChannel,
      notes: notes,
    });

    if(!this.isContractRegisterParent){
      this._api.saveContact(this.idContract, this.lstContacts)
      .subscribe(response => {
        this.formGroup = new FormGroup({
          _id: new FormControl(""),
          name: new FormControl("", Validators.required),
          phone: new FormControl("", Validators.minLength(10)),
          cellphone: new FormControl("", Validators.minLength(11)),
          email: new FormControl("", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
          contactType: new FormControl(""),
          contactValue: new FormControl(""),
          lstAditionalChannel: new FormControl(""),
          notes: new FormControl(""),
        });
        this.lstAditionalChannel = [];
        this.toggleShowDiv("newchanel");
      });
    } else {
      this.contactsUpdate.emit(this.lstContacts);
      this.formGroup = new FormGroup({
        _id: new FormControl(""),
        name: new FormControl("", Validators.required),
        phone: new FormControl("", Validators.minLength(10)),
        cellphone: new FormControl("", Validators.minLength(11)),
        email: new FormControl("", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
        contactType: new FormControl(""),
        contactValue: new FormControl(""),
        lstAditionalChannel: new FormControl(""),
        notes: new FormControl(""),
      });      
      this.toggleShowDiv("newchanel");
    }

    this.expanded = false;
    this.lblChannel = "Adicionar contato";
    this.lblbuton = "Salvar Contato";

  }

  changingType() {
    let type = this.formGroup.get("contactType").value;

    switch (type) {
      case "telefone":
        this.lblPlaceholder = "Digite seu Telefone";
        break;
      case "celular":
        this.lblPlaceholder = "Digite seu Celular";
        break;
      case "email":
        this.lblPlaceholder = "Digite seu E-mail";
        break;
      case "default":
        this.lblPlaceholder = "Digite o valor";
        break;
    }
  }

  checkPhone() {
    if (this.f.phone.errors)
      this.erroPhone = true;
    else
      this.erroPhone = false;
  }

  checkCellPhone() {
    if (this.f.cellphone.errors)
      this.erroCellPhone = true;
    else
      this.erroCellPhone = false;
  }

  checkEmail() {
    if (!this.formGroup.get("email").value) {
      this.erroEmail = false;
      return;
    }

    if (this.f.email.errors)
      this.erroEmail = true;
    else
      this.erroEmail = false;
  }

  toggleEditionMode(mode: string){
    this.editionMode = mode;
    if(mode === "visible"){
      this.lblbuton = "Atualizar Contato";
      this.lblChannel = "Editar canal de Contato";
    } else {
      this.lblChannel = "Adicionar contato";
      this.lblbuton = "Salvar Contato";
    }
  }

  removeContact(){
    this.lstContacts = this.lstContacts.filter(contact => contact._id != this.formGroup.value._id);
    if(!this.isContractRegisterParent){
      this._api.saveContact(this.idContract, this.lstContacts)
      .subscribe(response => {

      });          
    }
      this.lstAditionalChannel = [];
      this.toggleShowDiv("newchanel");      
      this.toggleEditionMode("hidden");
      this.formGroup = new FormGroup({
        _id: new FormControl(""),
        name: new FormControl("", Validators.required),
        phone: new FormControl("", Validators.minLength(10)),
        cellphone: new FormControl("", Validators.minLength(11)),
        email: new FormControl("", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
        contactType: new FormControl(""),
        contactValue: new FormControl(""),
        lstAditionalChannel: new FormControl(""),
        notes: new FormControl(""),
      });
      this.expanded = false;      
  }

  cancelEdition(){
    this.formGroup = new FormGroup({
      _id: new FormControl(""),
      name: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.minLength(10)),
      cellphone: new FormControl("", Validators.minLength(11)),
      email: new FormControl("", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
      contactType: new FormControl(""),
      contactValue: new FormControl(""),
      lstAditionalChannel: new FormControl(""),
      notes: new FormControl(""),
    });
    this.toggleEditionMode("hidden");
    this.expanded = false;
  }

}
