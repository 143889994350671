import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
export interface DialogData {
  valueMin: number,
  valueMax: number,
  cobrado: boolean,
  naoCobradoSempre: boolean,
  naoCobradoFatura: boolean,
  lastReadMin: Date,
  lastReadMax: Date,
  batch: string,
  pen: string,
  parcel: string,
  sisbov: string,
  boschNumber: string,   
  status: string;
}
@Component({
  selector: 'app-dialog-animal-list',
  templateUrl: './dialog-animal-list.component.html',
  styleUrls: ['./dialog-animal-list.component.scss']
})
export class DialogAnimalListComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  
  formGroup: FormGroup;

  
  constructor(
    public dialogRef: MatDialogRef<DialogAnimalListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      valueMin: new FormControl(this.data.valueMin),
      valueMax: new FormControl(this.data.valueMax),
      cobrado: new FormControl(this.data.cobrado),
      naoCobradoSempre: new FormControl(this.data.naoCobradoSempre),
      naoCobradoFatura: new FormControl(this.data.naoCobradoFatura),
      lastReadMin: new FormControl(this.data.lastReadMin),
      lastReadMax: new FormControl(this.data.lastReadMax),
      batch: new FormControl(this.data.batch),
      pen: new FormControl(this.data.pen),
      parcel: new FormControl(this.data.parcel),
      sisbov: new FormControl(this.data.sisbov),
      boschNumber: new FormControl(this.data.boschNumber),   
    });
  }

  translateStatusCheckBoxesToArray(){
    let statusArray: string[] = [];
        
    if(this.formGroup.value.cobrado)
      statusArray.push("Cobrado");
    if(this.formGroup.value.naoCobradoSempre)
      statusArray.push("Não Será Cobrado (Nunca)");
    if(this.formGroup.value.naoCobradoFatura)
      statusArray.push("Não Será Cobrado (Fatura)");

    return statusArray.join(',');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clean(){
    this.formGroup = new FormGroup({
      valueMin: new FormControl(""),
      valueMax: new FormControl(""),
      cobrado: new FormControl(false),
      naoCobradoSempre: new FormControl(false),
      naoCobradoFatura: new FormControl(false),
      lastReadMin: new FormControl(""),
      lastReadMax: new FormControl(""),
      batch: new FormControl(""),
      pen: new FormControl(""),
      parcel: new FormControl(""),
      sisbov: new FormControl(""),
      boschNumber: new FormControl(""),   
    });  
  }

  submit(){
    this.data.valueMin = this.formGroup.value.valueMin;
    this.data.valueMax = this.formGroup.value.valueMax;
    this.data.cobrado = this.formGroup.value.cobrado;
    this.data.naoCobradoSempre = this.formGroup.value.naoCobradoSempre;
    this.data.naoCobradoFatura = this.formGroup.value.naoCobradoFatura;
    this.data.lastReadMin = this.formGroup.value.lastReadMin;
    this.data.lastReadMax = this.formGroup.value.lastReadMax;
    this.data.batch = this.formGroup.value.batch;
    this.data.pen = this.formGroup.value.pen;
    this.data.parcel = this.formGroup.value.parcel;
    this.data.sisbov = this.formGroup.value.sisbov;
    this.data.boschNumber = this.formGroup.value.boschNumber;
    this.data.status = this.translateStatusCheckBoxesToArray();
  }

}
