<div class="row mt-4 header-labels" #target>
    <mat-accordion class="col col-12">
        <mat-expansion-panel hideToggle [(expanded)]="expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <label class="boschsansmedium blue mb-0"><b>{{ lblChannel }}</b></label>
                    <img class="icon-medium" src="../../../assets/Component 38 – 1.svg" />
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form class="form-area" [formGroup]="formGroup">
                <input type="hidden" formControlName="_id" />
                <div class="row">
                    <div class="col col-6 mb-3">
                        <mat-slide-toggle formControlName="ativo">Ativo</mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6">
                        <mat-form-field class="strech-overview">
                            <mat-label class="placeholder boschsansmedium blue mb-0">
                                Tipo de Serviço*
                            </mat-label>
                            <mat-select (selectionChange)="changingType()" formControlName="serviceType">
                                <mat-option value="Performance">
                                    Performance
                                </mat-option>
                                <!-- <mat-option value="Servico">
                                    Serviço
                                </mat-option> -->
                                <mat-option value="Financeiro">
                                    Financeiro
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-10" *ngIf="isPerformance">
                        <mat-form-field class="strech-overview">
                            <mat-label class="placeholder boschsansmedium blue mb-0">Sistemas Vinculados ao Serviço*
                            </mat-label>
                            <mat-select formControlName="linkedSystems" multiple>
                                <mat-option *ngFor="let linkedSystem of listLinkedSystems" [value]="linkedSystem._id">
                                    {{linkedSystem.name}}</mat-option>
                            </mat-select>                                                   
                        </mat-form-field>
                    </div>
                    <div class="col-2 button-area" *ngIf="isPerformance">
                        <p (click)="selectAllSystems()" *ngIf="!allPensSelected">Selecionar Todos</p>
                        <p (click)="selectAllSystems()" *ngIf="allPensSelected">Deselecionar Todos</p>                                                
                    </div>                    
                </div>
                <div class="row">
                    <div class="col col-6">
                        <p class="mb-0"><span><b>Data Início*</b></span></p>
                        <input class="form-datepicker" [matDatepicker]="picker1" formControlName="dtInicio"
                            placeholder="Selecionar Data" disabled />
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                    </div>
                    <div class="col col-6">
                        <p class="mb-0"><span><b>Data Término*</b></span></p>
                        <input class="form-datepicker" [matDatepicker]="picker2" formControlName="dtEnd"
                            placeholder="Selecionar Data" disabled />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                    </div>
                </div>
                <div class="row mt-2 mb-4">
                    <div class="col offset-8 col-4">
                        <mat-slide-toggle formControlName="endless">Término Indeterminado</mat-slide-toggle>
                    </div>
                </div>
                <div class="row pl-3">
                    <label class="blue"><strong>COBRANÇA E FATURAMENTO</strong></label>
                </div>
                <div class="row">
                    <div class="col col-6">
                        <mat-form-field class="strech-overview">
                            <mat-label class="placeholder boschsansmedium blue mb-0 mt-4">
                                Periocidade de Cobrança*
                            </mat-label>
                            <mat-select (selectionChange)="changingType()" formControlName="periodo">
                                <mat-option value="mensal">
                                    Mensal
                                </mat-option>
                                <mat-option value="trimestral">
                                    Trimestral
                                </mat-option>
                                <mat-option value="semestral">
                                    Semestral
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col col-6">
                        <label class="boschsansmedium mb-0 mt-4">Dia do Faturamento*</label>
                        <input type="number" class="form-control placeholder-ic-mail" formControlName="billDate"
                            placeholder="" min="1" max="31" (input)="checkBillDateInput()"/>
                        <span *ngIf="erroBillDate" class="red">O dia deve ser entre 1 e 31 de cada mês</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-6">
                        <label class="boschsansmedium mb-0 mt-4">Prazo para Pagamento (dias)*</label>
                        <input type="number" max="31" min="1" class="form-control placeholder-ic-mail" formControlName="prazo"
                            placeholder="" (input)="checkPrazoDateInput()"/>
                            <span *ngIf="erroPrazoDate" class="red">O dia deve ser entre 1 e 31 de cada mês</span>
                    </div>
                    <div class="col col-6" *ngIf="isHeadPerCycle">
                        <label class="boschsansmedium mb-0 mt-4">Nº Meses do Período Bônus</label>
                        <input type="text" class="form-control placeholder-ic-mail" formControlName="periodoBonus"
                            placeholder="" />
                        <!-- <span *ngIf="erroEmail" class="red">Digite um e-mail válido</span> -->
                    </div>
                </div>
                <div class="row mt-2 mb-4">
                    <div class="col col-6">
                        <mat-slide-toggle *ngIf="isHeadPerCycle" formControlName="cobranca">Cobrança Completa após Saída</mat-slide-toggle>
                    </div>
                </div>
                <div class="row pl-3">
                    <label class="blue"><strong>VALOR</strong></label>
                </div>
                <div class="row">
                    <div class="col col-6">
                        <span><b>Valor*</b></span>
                        <input class="form-control" currencyMask formControlName="value" placeholder="Valor" />

                        <mat-form-field class="strech-overview mt-2" *ngIf="this.isHeadPerCycle">
                            <mat-label class="placeholder boschsansmedium blue mb-0 mt-4">
                                Tipo de Pagamento
                            </mat-label>
                            <mat-select formControlName="chargeOnCycleEnd">
                                <mat-option value="false">
                                    Pagamento parcelado
                                </mat-option>
                                <mat-option value="true">
                                    Pagamento ao final do ciclo
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col col-6" *ngIf="isPerformance">
                        <mat-form-field class="strech-overview">
                            <mat-label class="placeholder boschsansmedium blue mb-0">
                                Por*
                            </mat-label>
                            <mat-select formControlName="cobrarPor" (selectionChange)="changingChargingType()">
                                <mat-option value="HeadPerCycle">
                                    Cabeça/Ciclo
                                </mat-option>
                                <mat-option value="HeadPerMonth">
                                    Cabeça/Mês
                                </mat-option>
                                <mat-option value="HeadPerDay">
                                    Cabeça/Dia
                                </mat-option>
                                <mat-option value="PerSystem">
                                    Sistema
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col col-6" *ngIf="isHeadPerCycle">
                        <label class="boschsansmedium mb-0 mt-4">Duração do Ciclo (meses)*</label>
                        <input type="text" class="form-control placeholder-ic-mail" formControlName="cicle"
                            placeholder="" />
                        <!-- <span *ngIf="erroEmail" class="red">Digite um e-mail válido</span> -->
                    </div>
                    <div class="col col-6" *ngIf="!isPerformance">
                        <label class="boschsansmedium mb-0 mt-4">Número do Contrato*</label>
                        <input type="text" class="form-control placeholder-ic-mail" formControlName="contractNumber"
                            placeholder="" />
                        <!-- <span *ngIf="erroEmail" class="red">Digite um e-mail válido</span> -->
                    </div>                    
                </div>
                <div class="row pl-3">
                    <label class="blue"><strong>OBSERVAÇÕES</strong></label>
                </div>
                <div class="row">
                    <div class="col col-12">
                        <textarea rows="7" class="form-control" formControlName="observacao"
                            placeholder="Atenção: as informações preenchidas neste campo serão impressas em todas as faturas desse serviço"></textarea>
                    </div>
                </div>
                <div class="row">   
                    <div class="col-3 mt-5 button-area" [style.visibility]="editionMode">
                        <p (click)="cancelEdition()">Cancelar</p>                                                    
                    </div>                      
                    <div class="offset-4 col-3 mt-5 button-area">    
                        <button type="button" class="mat-focus-indicator button-style mat-flat-button mat-button-base"
                            (click)="saveService()" [disabled]="!formGroup.dirty" mat-flat-button>
                            {{ lblbuton }}
                        </button>
                    </div>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="base-table-container container-fluid">
    <div class="row mt-5 mb-3">
        <label class="blue"><strong>SERVIÇOS VINCULADOS AO CONTRATO</strong></label>
    </div>
</div>

<div class="col col-12 no-padding" *ngFor="let service of lstServices">
    <div *ngIf="service.type === 'Performance'" class="painel-full">
        <div class="row">
            <div class="header-painel">
                <div class="row">
                    <div class="col col-10">
                        <mat-icon class="icon-medium" svgIcon="svg-coin"></mat-icon>
                        <label class="boschsansmedium pl-2"> Serviço {{ service.type }} </label>
                    </div>
                    <div class="col col-2" *ngIf="!isContractRegisterParent">
                        <a href="javascript:void(0);" style="cursor: pointer;" class="text-white"
                            (click)="edit(service, target)">
                            <mat-icon class="icon-medium" svgIcon="svg-ic-pen"></mat-icon>
                            <label class="boschsansmedium" style="cursor: pointer;">Editar</label>
                        </a>
                    </div>
                    <div class="col col-2" *ngIf="isContractRegisterParent">
                        <a href="javascript:void(0);" style="cursor: pointer;" class="text-white"
                            (click)="exclude(service.index)">
                            <mat-icon class="icon-medium" svgIcon="svg-clear"></mat-icon>
                            <label class="boschsansmedium" style="cursor: pointer;">Excluir</label>
                        </a>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col ml-md-3 vg border-0">
                <label class="blue"><strong>DETALHES DO SERVIÇO</strong></label>
            </div>
            <div class="col ml-md-2 vg">
                <label class="blue"><strong>COBRANÇA E FATURAMENTO</strong></label>
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Tipo de Serviço</b></span> <br />
                <span class="">{{ service.type }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Periodo de Fechamento</b></span> <br />
                <span class="">{{ service.billingPeriod | titlecase }}</span> <br />
            </div>
            <div class="col ml-md-2 vg border-0" >
                <span *ngIf="service.billingType === 'HeadPerCycle'"><b>Duração do Ciclo</b></span> <br /> 
                <span class="" *ngIf="service.billingType === 'HeadPerCycle'">{{ service.numberOfMonthsOfCycle }} meses</span>
            </div>
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Data de Início</b></span> <br />
                <span class="">{{ service.startingDate | date:'dd/MM/yyyy' }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Dia do Faturamento</b></span> <br />
                <span class="">{{ service.billingDay }}</span> <br />
            </div>
            <div class="col ml-md-2">
            </div>
            <!-- <div class="col ml-md-2 vg border-0">
                <span><b>Faturas em Aberto</b></span>
                <p class="">{{ service.faturaAberto }}</p>
            </div> -->
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Data de Término</b></span> <br />
                <span class="">{{ service.endingDate | date:'dd/MM/yyyy' }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Prazo para Pagamento</b></span> <br />
                <span class="">{{ service.paymentTerm }} dias</span> <br />
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col ml-md-3 vg border-0">
                <span><b>Sistemas Vinculados</b></span> <br />
                <label *ngFor="let penId of service.penIdList">{{ penId.name }} &nbsp;</label> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Valor</b></span> <br />
                <span class="">{{ service.chargeValue | currency:'BRL':'symbol':'1.2-2':'br' }} por
                    {{ service.billingType  | enum: "ServiceBillType" }}</span> <br />
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
    </div>

    <div *ngIf="service.type === 'Financeiro'" class="painel-full">
        <div class="row">
            <div class="header-painel">
                <div class="row">
                    <div class="col col-10">
                        <mat-icon class="icon-medium" svgIcon="svg-coin"></mat-icon>
                        <label class="boschsansmedium pl-2"> Serviço {{ service.type }} </label>
                    </div>
                    <div class="col col-2" >
                        <a href="javascript:void(0);" style="cursor: pointer;" class="text-white"
                            (click)="edit(service, target)">
                            <mat-icon class="icon-medium" svgIcon="svg-ic-pen"></mat-icon>
                            <label class="boschsansmedium">Editar</label>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col ml-md-3 vg border-0">
                <label class="blue"><strong>DETALHES DO SERVIÇO</strong></label>
            </div>
            <div class="col ml-md-2 vg">
                <label class="blue"><strong>COBRANÇA E FATURAMENTO</strong></label>
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Tipo de Serviço</b></span> <br />
                <span class="">{{ service.type }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Periodo de Fechamento</b></span> <br />
                <span class="">{{ service.billingPeriod | titlecase }}</span> <br />
            </div>
            <div class="col ml-md-2 vg border-0">

            </div>
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Data de Início</b></span> <br />
                <span class="">{{ service.startingDate | date:'dd/MM/yyyy' }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Dia do Faturamento</b></span> <br />
                <span class="">{{ service.billingDay }}</span> <br />
            </div>
            <div class="col ml-md-2 vg border-0">

            </div>
        </div>
        <div class="row row-height">
            <div class="col ml-md-3 vg border-0">
                <span><b>Date de Término</b></span> <br />
                <span class="">{{ service.endingDate | date:'dd/MM/yyyy' }}</span> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Prazo para Pagamento</b></span> <br />
                <span class="">{{ service.paymentTerm }}</span> <br />
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col ml-md-3 vg border-0">
                <span><b>Número do Contrato</b></span> <br />
                <label>{{ service.contractNumber }} &nbsp;</label> <br />
            </div>
            <div class="col ml-md-2 vg">
                <span><b>Valor</b></span> <br />
                <span class="">{{ service.chargeValue | currency:'BRL':'symbol':'1.2-2':'br' }}</span> <br />
            </div>
            <div class="col ml-md-2">
            </div>
        </div>
    </div>
</div>