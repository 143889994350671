import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';

export interface DialogData {
  code: string;
  valueMin: number;
  valueMax: number;
  status: string;
  dateMin: Date;
  dateMax: Date;
  farm: String;
  contractNumber: number;
  aFaturar: boolean;
  emAnalise: boolean;
  aguardandoNF: boolean;
  encerrada: boolean;
  cancelada: boolean;
}

@Component({
  selector: 'app-dialog-bill-contract-list',
  templateUrl: './dialog-bill-contract-list.component.html',
  styleUrls: ['./dialog-bill-contract-list.component.scss']
})
export class DialogBillContractListComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogBillContractListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      aFaturar: new FormControl(this.data.aFaturar),
      emAnalise: new FormControl(this.data.emAnalise),
      aguardandoNF: new FormControl(this.data.aguardandoNF),
      encerrada: new FormControl(this.data.encerrada),
      cancelada: new FormControl(this.data.cancelada),
      dateMin: new FormControl(this.data.dateMin),
      dateMax: new FormControl(this.data.dateMax),
      valueMin: new FormControl(this.data.valueMin),
      valueMax: new FormControl(this.data.valueMax),
      code: new FormControl(this.data.code),
      farm: new FormControl(this.data.farm),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clean() {
    this.formGroup = new FormGroup({
      aFaturar: new FormControl(false),
      emAnalise: new FormControl(false),
      aguardandoNF: new FormControl(false),
      encerrada: new FormControl(false),
      cancelada: new FormControl(false),
      dateMin: new FormControl(""),
      dateMax: new FormControl(""),
      valueMin: new FormControl(""),
      valueMax: new FormControl(""),
      code: new FormControl(""),
      farm: new FormControl(""),
    });
  }

  translateStatusCheckBoxesToArray() {
    let statusArray: string[] = [];
    if (this.formGroup.value.aFaturar)
      statusArray.push("à faturar")
    if (this.formGroup.value.emAnalise)
      statusArray.push("em análise")
    if (this.formGroup.value.aguardandoNF)
      statusArray.push("aguardando nf")
    if (this.formGroup.value.encerrada)
      statusArray.push("encerrado")
    if (this.formGroup.value.cancelada)
      statusArray.push("cancelado")
    return statusArray.join(',');
  }

  submit() {
    this.data.aFaturar = this.formGroup.value.aFaturar;
    this.data.emAnalise = this.formGroup.value.emAnalise;
    this.data.aguardandoNF = this.formGroup.value.aguardandoNF;
    this.data.encerrada = this.formGroup.value.encerrada;
    this.data.cancelada = this.formGroup.value.cancelada;
    this.data.status = this.translateStatusCheckBoxesToArray();
    this.data.code = this.formGroup.value.code;
    this.data.valueMin = this.formGroup.value.valueMin;
    this.data.valueMax = this.formGroup.value.valueMax;
    this.data.dateMin = this.formGroup.value.dateMin;
    this.data.dateMax = this.formGroup.value.dateMax;
    this.data.farm = this.formGroup.value.farm;
  }
}
