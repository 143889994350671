import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { SvgIconService } from '../services/svg-icon.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from '../shared/loading/loading.service';
import { LoadingComponent } from '../shared/loading/loading.component';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

  @ViewChild(LoadingComponent, {}) loadingComponent: LoadingComponent;

  constructor(
    private router: Router,
    private updateRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private iconService: SvgIconService,
    private route: ActivatedRoute,
    private _invoice: InvoiceService
    ) { }
    
  idContract: string;
  tabIndex = 0;
  loadingBehaviorSubject: BehaviorSubject<boolean>;

  ngOnInit(): void {
    this.idContract = this.route.snapshot.params['id'];
    this._invoice.setIdContract(this.idContract);
  }
  
  ngOnDestroy() {
    this.loadingService.billsAndContractsLoading.complete();
  }

  ngAfterViewInit() {
    this.changeOnLoadIfAlreadyInServices(this.router.url);
    this.watchLoading();
  }

  changeOnLoadIfAlreadyInServices(url: string) {
    if (url.includes("services")) {
      this.tabIndex = 1;
      this.updateRef.detectChanges();
    }
  }

  tabChange(tabNumber) {
    switch (tabNumber) {
      case 0:
        return this.router.navigate([`contract/${this.idContract}/bills`]);
      case 1:
        return this.router.navigate([`contract/${this.idContract}/services`]);
    }
  }

  watchLoading() {
    this.loadingService.billsAndContractsLoading.subscribe(x => {
      if (x === true) {
        this.loadingComponent.enabled = true;
      } else {
        this.loadingComponent.enabled = false;
      }
    });
  }



}
