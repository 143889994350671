import { Component, OnInit, ViewChild } from '@angular/core';
import { TableIndex } from 'src/app/domain/interfaces/table-index.interface';
import { BaseTableInvoiceComponent } from 'src/app/shared/base-table-invoice/base-table-invoice.component';
import { API } from 'src/app/shared/app.api';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @ViewChild(BaseTableInvoiceComponent, { static: true })
  baseTable: BaseTableInvoiceComponent;

  constructor(private _invoice: InvoiceService) { }
  queryParam: string;

  indexes: TableIndex[] = [
    { label: "ANIMAIS", property: "qtyOfAnimals" },
    // { label: "LOTE", property: "numContract" },
    // { label: "STATUS", property: "contractOwner" }
  ];

  specialColumnsIndexes: TableIndex[] = [
    { label: "STATUS", property: "status" },
    { label: "DATA", property: "date" },
    { label: "OBSERVAÇÃO", property: "observation" },
    { label: "VALOR", property: "value" }
  ];


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.queryParam = window.location.pathname.split("/")[2];
    this.getHistories(this.queryParam);
  }

  getHistories(queryParam: string){
    if (!queryParam) { return; }

    this.baseTable.setupTable(
      `${API}/invoices/${queryParam}/history`,
      this.indexes,
      'Invoice',
      this.specialColumnsIndexes,
      'History'
    );

    this.baseTable.reorganizeColumns([
      "status",
      "date",
      "value",
      "qtyOfAnimals",
      "observation"
    ]);

    this.baseTable.loadTable();
  }


}
