import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from "@angular/core";

export const rangeOfValues = (
  page: number,
  pageSize: number,
  length: number
) => {
  if (length == 0 || pageSize == 0) {
    return `0 até ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} até ${length}`;
};

@Injectable()
export class PtBrMatPaginator extends MatPaginatorIntl {
  firstPageLabel = "Primeira página";
  itemsPerPageLabel = "Itens por página";
  nextPageLabel = "Proxima página";
  previousPageLabel = "Página anterior";
  getRangeLabel = rangeOfValues;
  lastPageLabel = "Ultima página";
}
