<a href="javascript:void(0);" (click)="clean()" class="blue modal-title" mat-dialog-title>Limpar Filtros</a>
<div mat-dialog-content>
    <form class="form-area" [formGroup]="formGroup">
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Data de início</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <p class="mb-0"><span><b>De</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker1" formControlName="startingDateMin"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </div>
            <div class="col col-6">
                <p class="mb-0"><span><b>Até</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker2" formControlName="startingDateMax"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Data de término</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <p class="mb-0"><span><b>De</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker3" formControlName="endingDateMin"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 disabled="false"></mat-datepicker>
            </div>
            <div class="col col-6">
                <p class="mb-0"><span><b>Até</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker4" formControlName="endingDateMax"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4 disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Data da próxima fatura</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <p class="mb-0"><span><b>De</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker5" formControlName="nextInvoiceDateMin"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                <mat-datepicker #picker5 disabled="false"></mat-datepicker>
            </div>
            <div class="col col-6">
                <p class="mb-0"><span><b>Até</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker6" formControlName="nextInvoiceDateMax"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                <mat-datepicker #picker6 disabled="false"></mat-datepicker>
            </div>
        </div>

        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Fazenda</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="farm" placeholder="Nome" />
            </div>
        </div>
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Número do contrato</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="contractNumber" placeholder="Número do contrato" />
            </div>
        </div>        
    </form>
</div>
<div mat-dialog-actions class="row">
    <div class="offset-4 col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
            (click)="submit()" [mat-dialog-close]="data">
            Aplicar Filtros
        </button>
    </div>
</div>