<div class="root-container">
  <section id="row-header" (scroll)="onWindowScroll($event);">
    <div class="row">
      <img class="navbar-img img-fluid" src="assets/bosch-dashboard_navbar.png" alt="bosch navigation bar" />
    </div>
    <div class="row">
      <div class="col-xl-3 offset-xl-2 col-3 offset-1 bosch-navbar">
        <img class="bosch-logo" src="../../../assets/bosch-logo-5.jpg" />
      </div>
      <div class="col-xl-6 col-6 bosch-navbar user-menu">
        <span class="navbar-user">
          <b> {{fullName}} </b>
          <mat-icon class="img-icon" svgIcon="svg-user"></mat-icon>
        </span>

        <span class="navbar-user" (click)="logout()" title="Logout">
          <mat-icon class="logout-icon" svgIcon="svg-logout"></mat-icon>
        </span>
      </div>
      <div class="navbar-divider"></div>
    </div>
  </section>
  <div class="row"> <!-- style="padding: 0; background-color: #f7f7f7;"> -->
    <ng-content> </ng-content>
    <!-- <div class="content-area col-6 offset-3">

    </div> -->
  </div>
</div>