<a href="javascript:void(0);" (click)="clean()" class="blue modal-title" mat-dialog-title>Limpar Filtros</a>
<div mat-dialog-content>
    <form class="form-area" [formGroup]="formGroup">
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Valor da Fatura</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <span><b>Mínimo</b></span>
                <input class="form-control" currencyMask formControlName="valueMin" placeholder="Valor Mínimo" />
            </div>
            <div class="col col-6">
                <span><b>Máximo</b></span>
                <input class="form-control" currencyMask formControlName="valueMax" placeholder="Valor Máximo" />
            </div>
        </div>
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Código da fatura</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="code" placeholder="Código da fatura" />
            </div>
        </div>
        <!-- <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Fazenda</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="farm" placeholder="Nome" />
            </div>
        </div>      -->
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Status da Fatura</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="aFaturar">À Faturar</mat-checkbox>
            </div>
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="encerrada">Encerrada</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="emAnalise">Em Análise</mat-checkbox>
            </div>
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="cancelada">Cancelada</mat-checkbox>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="aguardandoNF">Aguardando NF</mat-checkbox>
            </div>
        </div>

        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Data de Faturamento</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <p class="mb-0"><span><b>De</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker1" formControlName="dateMin"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </div>
            <div class="col col-6">
                <p class="mb-0"><span><b>Até</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker2" formControlName="dateMax"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            </div>
        </div>

    </form>
</div>
<div mat-dialog-actions class="row">
    <div class="offset-4 col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base" (click)="submit()"
            [mat-dialog-close]="data">
            Aplicar Filtros
        </button>
    </div>
</div>