import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { SvgIconService } from "src/app/services/svg-icon.service";
import { DOCUMENT } from '@angular/common';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';

import { AuthService } from './../../services/auth.service';

@Component({
  selector: "app-navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class NavigationBarComponent implements OnInit {

  public fullName: string;

  constructor(
    private iconService: SvgIconService,
    @Inject(DOCUMENT) document,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.authService.getUserProfile().then((res) => {
      this.fullName = `${res.firstName || ''} ${res.lastName || ''}`;
    });
  }

  logout() {
    this.authService.logOut();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // if (window.pageYOffset > 70) {
    //   let element = document.getElementById('row-header');
    //   element.classList.add('sticky');
    // } else {
    //   let element = document.getElementById('row-header');
    //   element.classList.remove('sticky');
    // }
  }
}
