import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, map, finalize, delay } from "rxjs/operators";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { FormGroup, FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { TableIndex } from "src/app/domain/interfaces/table-index.interface";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "../loading/loading.service";
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogBillListComponent } from 'src/app/dashboard/dialog-bill-list/dialog-bill-list.component';
import { SvgIconService } from 'src/app/services/svg-icon.service';
import { DialogContractListComponent } from 'src/app/dashboard/dialog-contract-list/dialog-contract-list.component';
import { BaseRequests } from "../base-requests";

registerLocaleData(localeBr, 'br');

@Component({
  selector: "app-base-table",
  templateUrl: "./base-table.component.html",
  styleUrls: ["./base-table.component.scss"]
})
export class BaseTableComponent implements OnInit {
  @ViewChild(MatPaginator, {}) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public showRows: boolean = false;
  public sortDefault: boolean = false;
  urlQueryParams: string = "";
  filterObject = {
    startingDateMin: "",
    startingDateMax: "",
    endingDateMin: "",
    endingDateMax: "",
    nextInvoiceDateMin: "",
    nextInvoiceDateMax: "",
    farm: "",
    contractNumber: "",
  };

  invoiceFilterObject = {
    aFaturar: false,
    emAnalise: false,
    aguardandoNF: false,
    encerrada: false,
    cancelada: false,
    dateMin: "",
    dateMax: "",
    valueMin: "",
    valueMax: "",
    code: "",
    farm: "",
    contractNumber: "",
  }

  indexes: TableIndex[] = [];
  specialColumnsIndexes: TableIndex[] = [];

  displayedColumns: string[] = [];

  orderByOptions: string[] = [undefined];
  filterOptions: string[] = [undefined];

  loadingSubjectName: string;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  formGroup: FormGroup = new FormGroup({
    searchControl: new FormControl(""),
    orderControl: new FormControl(""),
    filterControl: new FormControl("")
  });

  baseUrl: string;
  component: string
  grid: string
  offset = 0;
  limit = 10;

  // MatPaginator Inputs
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private http: HttpClient,
    private updateRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private router: Router,
    public dialog: MatDialog,
    private iconService: SvgIconService,
    private baseRequests: BaseRequests,
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (sortHeaderId === 'invoiceStatusEnum') {
        return;
      }

      const value: any = data[sortHeaderId];
      return typeof value === "string" ? value.toLowerCase() : value;
    };
  }


  ngOnDestroy() {
  }

  setupTable(
    baseUrl: string,
    indexes: TableIndex[],
    loadingSubjectName: string,
    component: string,
    grid: string,
    specialColumnsIndexes?: TableIndex[]
  ) {
    this.baseUrl = baseUrl;
    this.indexes = indexes;
    this.component = component;
    this.grid = grid;
    this.loadingSubjectName = loadingSubjectName;

    let columns = [];
    let columnsFilter = [];
    columns = this.indexes.map(x => x.property);
    columnsFilter = this.indexes.map(x => x.label);

    if (specialColumnsIndexes) {
      this.specialColumnsIndexes = specialColumnsIndexes;
      columns = columns.concat(specialColumnsIndexes.map(x => x.property));
      columnsFilter = columnsFilter.concat(specialColumnsIndexes.map(x => x.label));
    }

    columnsFilter.forEach(x => {
      this.filterOptions.push(x);
    });

    this.displayedColumns = columns;
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();

  }

  async loadTable() {
    await this.observableRequest(this.getPaginationParams(0));
  }

  async observableRequest(
    page: string,
  ) {
    let fullUrl = this.baseUrl;

    fullUrl += page;

    fullUrl += `&pageSize=${this.pageSize}`;

    if (this.urlQueryParams) {
      fullUrl += this.urlQueryParams;
    }
    if (this.sort.active && !this.sortDefault) {
      fullUrl += `&orderBy=${this.sort.direction === 'asc' || '' ? this.sort.active : '-' + this.sort.active}`;
    }
        
    setTimeout(async () => {
      this.loadingService.routeLoading(this.loadingSubjectName, true);
      let result = await this.baseRequests.getRequest(fullUrl);
      this.length = result.data !== undefined ?
        result.data.itemsCount : result.count
      this.dataSource.data = result.data !== undefined ?
        result.data.listOfItems : result.results;
      this.loadingService.routeLoading(this.loadingSubjectName, false);
    }, 1000);
    
  }

  getPaginationParams(pageIndex: number) {
    let stringParam = "?pageNum=" + pageIndex;
    return stringParam;
  }

  getColumnParamProperty(columnName: string) {
    const allColumns = this.indexes.concat(this.specialColumnsIndexes);
    return allColumns.find(x => x.label === columnName)?.property;
  }

  searchOrderFilterOrPaginate(pageEvent?: PageEvent) {
    const searchValue = this.formGroup.get("searchControl").value;

    this.pageSize = pageEvent.pageSize;

    const filterValue = this.getColumnParamProperty(
      this.formGroup.get("filterControl").value
    );

    if (!pageEvent) {
      this.matPaginator.pageIndex = 0;
    }

    return this.observableRequest(
      this.getPaginationParams(pageEvent ? pageEvent.pageIndex : 0)
    );
  }

  reorganizeColumns(reorganizedColumns: string[]) {
    reorganizedColumns.forEach(x => {
      if (!this.displayedColumns.find(y => y === x)) {
        throw new Error(
          `Column ${x} to be reorganized is not defined in Table Setup`
        );
      }
    });

    this.displayedColumns = reorganizedColumns;
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();
  }

  getStatusChipColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#ffffff";
      default:
        return "#e6e6e6";
    }
  }
  getStatusBorderColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#e6e6e6";
    }
  }

  getStatusTextColor(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#ffffff";
    }
  }

  setStatusBorder(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "1px solid";
      default:
        return "none";
    }
  }

  details(row: any) {
    //console.log(row)
    return this.router.navigate([`${this.component}/${row._id}`]);
  }

  openDialogFilter(component: string): void {

    switch (component) {
      case "FATURAMENTOS":
        {
          const dialogRef = this.dialog.open(DialogBillListComponent, {
            width: '525px',
            data: this.invoiceFilterObject
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.invoiceFilterObject.aFaturar = result.aFaturar;
              this.invoiceFilterObject.emAnalise = result.emAnalise;
              this.invoiceFilterObject.aguardandoNF = result.aguardandoNF;
              this.invoiceFilterObject.encerrada = result.encerrada;
              this.invoiceFilterObject.cancelada = result.cancelada;
              this.invoiceFilterObject.dateMin = result.dateMin;
              this.invoiceFilterObject.dateMax = result.dateMax;
              this.invoiceFilterObject.valueMin = result.valueMin;
              this.invoiceFilterObject.valueMax = result.valueMax;
              this.invoiceFilterObject.code = result.code;
              this.invoiceFilterObject.farm = result.farm;
              this.invoiceFilterObject.contractNumber = result.contractNumber;
              this.buildInvoiceURLQueryParams(result.status, result.code, result.valueMin, result.valueMax, result.dateMin, result.dateMax, result.farm, result.contractNumber);
              this.loadTable();
            }
          });
          break;
        }
      case "CONTRATOS":
        {
          const dialogRef = this.dialog.open(DialogContractListComponent, {
            width: '525px',
            data: this.filterObject
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.filterObject.startingDateMin = result.startingDateMin;
              this.filterObject.startingDateMax = result.startingDateMax;
              this.filterObject.endingDateMin = result.endingDateMin;
              this.filterObject.endingDateMax = result.endingDateMax;
              this.filterObject.nextInvoiceDateMin = result.nextInvoiceDateMin;
              this.filterObject.nextInvoiceDateMax = result.nextInvoiceDateMax;
              this.filterObject.farm = result.farm;
              this.filterObject.contractNumber = result.contractNumber;

              this.buildURLQueryParams(result.startingDateMin, result.startingDateMax, result.endingDateMin, result.endingDateMax, result.nextInvoiceDateMin, result.nextInvoiceDateMax, result.farm, result.contractNumber);
              this.loadTable();
            }
          });
          break;
        }
    }
  }

  buildInvoiceURLQueryParams(status, code, valueMin, valueMax, dateMin, dateMax, farm, contractNumber) {
    this.urlQueryParams = "";
    if (status && status !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&status=${status}`);
    }
    if (code && code !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&code=${code}`);
    }
    if (valueMin && valueMin !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMin=${valueMin}`);
    }
    if (valueMax && valueMax !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMax=${valueMax}`);
    }
    if (dateMin && dateMin !== "") {
      const month = dateMin.getMonth() < 9 ? "0" + (dateMin.getMonth() + 1) : (dateMin.getMonth() + 1);
      const day = dateMin.getDate() < 10 ? "0" + dateMin.getDate() : dateMin.getDate();
      const dateFormat = dateMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&dateMin=${dateFormat}`);
    }
    if (dateMax && dateMax !== "") {
      const month = dateMax.getMonth() < 9 ? "0" + (dateMax.getMonth() + 1) : (dateMax.getMonth() + 1);
      const day = dateMax.getDate() < 10 ? "0" + dateMax.getDate() : dateMax.getDate();
      const dateFormat = dateMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&dateMax=${dateFormat}`);
    }
    if (farm && farm !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&farm=${farm}`);
    }
    if (contractNumber && contractNumber !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&contractNumber=${contractNumber}`);
    }
  }

  buildURLQueryParams(startingDateMin, startingDateMax, endingDateMin, endingDateMax, nextInvoiceDateMin, nextInvoiceDateMax, farm, contractNumber) {
    this.urlQueryParams = "";
    if (startingDateMin && startingDateMin !== "") {
      const month = startingDateMin.getMonth() < 9 ? "0" + (startingDateMin.getMonth() + 1) : (startingDateMin.getMonth() + 1);
      const day = startingDateMin.getDate() < 10 ? "0" + startingDateMin.getDate() : startingDateMin.getDate();
      const dateFormat = startingDateMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&startingDateMin=${dateFormat}`);
    }
    if (startingDateMax && startingDateMax !== "") {
      const month = startingDateMax.getMonth() < 9 ? "0" + (startingDateMax.getMonth() + 1) : (startingDateMax.getMonth() + 1);
      const day = startingDateMax.getDate() < 10 ? "0" + startingDateMax.getDate() : startingDateMax.getDate();
      const dateFormat = startingDateMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&startingDateMax=${dateFormat}`);
    }
    if (endingDateMin && endingDateMin !== "") {
      const month = endingDateMin.getMonth() < 9 ? "0" + (endingDateMin.getMonth() + 1) : (endingDateMin.getMonth() + 1);
      const day = endingDateMin.getDate() < 10 ? "0" + endingDateMin.getDate() : endingDateMin.getDate();
      const dateFormat = endingDateMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&endingDateMin=${dateFormat}`);
    }
    if (endingDateMax && endingDateMax !== "") {
      const month = endingDateMax.getMonth() < 9 ? "0" + (endingDateMax.getMonth() + 1) : (endingDateMax.getMonth() + 1);
      const day = endingDateMax.getDate() < 10 ? "0" + endingDateMax.getDate() : endingDateMax.getDate();
      const dateFormat = endingDateMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&endingDateMax=${dateFormat}`);
    }
    if (nextInvoiceDateMin && nextInvoiceDateMin !== "") {
      const month = nextInvoiceDateMin.getMonth() < 9 ? "0" + (nextInvoiceDateMin.getMonth() + 1) : (nextInvoiceDateMin.getMonth() + 1);
      const day = nextInvoiceDateMin.getDate() < 10 ? "0" + nextInvoiceDateMin.getDate() : nextInvoiceDateMin.getDate();
      const dateFormat = nextInvoiceDateMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&nextInvoiceDateMin=${dateFormat}`);
    }
    if (nextInvoiceDateMax && nextInvoiceDateMax !== "") {
      const month = nextInvoiceDateMax.getMonth() < 9 ? "0" + (nextInvoiceDateMax.getMonth() + 1) : (nextInvoiceDateMax.getMonth() + 1);
      const day = nextInvoiceDateMax.getDate() < 10 ? "0" + nextInvoiceDateMax.getDate() : nextInvoiceDateMax.getDate();
      const dateFormat = nextInvoiceDateMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&nextInvoiceDateMax=${dateFormat}`);
    }
    if (farm && farm !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&farm=${farm}`);
    }
    if (contractNumber && contractNumber !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&code=${contractNumber}`);
    }
  }

  newContract() {

  }

  async sortData(event: any) {
    if (this.sort.direction !== '') {
      this.sortDefault = false;
    }
    else {
      this.sortDefault = true;
    }
    this.showRows = true;
    await this.observableRequest(this.getPaginationParams(this.matPaginator.pageIndex))
    this.showRows = false;
  }
}
