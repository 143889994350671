import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onActivate(event) {
    const scrollToTop = window.setInterval(() => {
      this.scrollBasedOnPosition(window.pageYOffset, scrollToTop);
    }, 16);

    return scrollToTop;
  }

  scrollBasedOnPosition(pos: number, interval: number) {
    if (pos > 0) {
      window.scrollTo(0, pos - 200);
    } else {
      window.clearInterval(interval);
    }
  }
}
