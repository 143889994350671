import { Pipe, PipeTransform } from "@angular/core";
import { BillStatus } from "src/app/domain/enums/bill-status.enum";
import { BillType } from "src/app/domain/enums/bill-type.enum";
import { ContactType } from 'src/app/domain/enums/contact-type.enum';
import { ServiceBillType } from 'src/app/domain/enums/service-bill-type.enum';

@Pipe({
  name: "enum"
})
export class EnumPipe implements PipeTransform {
  transform(value: any, enumType: string): any {
    switch (enumType) {
      case "BillStatus":
        return BillStatus[value];
      case "BillType":
        return BillType[value];
      case "ContactType":
        return ContactType[value];
      case "ServiceBillType":
        return ServiceBillType[value];
    }

    return null;
  }
}
