<div mat-dialog-content>

    <label class="modal-title boschsansmedium blue mb-0"><b>{{ title }}</b></label>


    <div class="form-area">
        <div class="row mt-3 mb-5">
            <div class="col col-12">
                <span><b>{{ message }}</b></span>
            </div>
        </div>           
    </div>
</div>
<div mat-dialog-actions class="row">
    <div class="col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
        (click)="onNoClick()" >
        Não
    </button>
    </div>
    <div class="offset-4 col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
            (click)="submit()" >
            Sim
        </button>
    </div>
</div>