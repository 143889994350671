<div id="contacts" class="col col-12 pt-5">
    <div class="row pl-3">
        <label class="blue"><strong>INFORMAÇÕES DE CONTATO</strong></label>
    </div>
    <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile *ngFor="let contact of lstContacts">
            <div class="col col-12 painel">
                <div class="row">
                    <div class="header-painel">
                        <div class="row">
                            <div class="col col-9">
                                <mat-icon class="icon-medium" svgIcon="svg-user2"></mat-icon>
                                <label class="boschsansmedium pl-2"> {{ contact.name }} </label>
                            </div>
                            <div class="col col-3">
                                <a href="javascript:void(0);" style="cursor: pointer;" class="text-white"
                                    (click)="edit(contact, target)">
                                    <mat-icon class="icon-medium" svgIcon="svg-ic-pen"></mat-icon>
                                    <label class="boschsansmedium" style="cursor: pointer;">Editar</label>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col pt-4 vg border-0">
                        <div  class="row">
                            <div *ngIf="contact.email" class="col col-12">
                                <img class="icon-medium" src="../../../assets/Component 40 – 1.svg" />
                                <label class="boschsansmedium pl-2 blue">E-mail &nbsp;</label>
                                <label> {{ contact.email }} </label>
                            </div>
                        </div>
                        <div  class="row">
                            <div *ngIf="contact.phone" class="col col-6">
                                <img class="icon-medium" src="../../../assets/Component 39 – 1.svg" />
                                <label class="boschsansmedium pl-2 blue">Telefone &nbsp;</label>
                                <label> {{ contact.phone | mask: '(00) 0000-0000' }} </label>
                            </div>

                            <div *ngIf="contact.cellphone" class="col col-6">
                                <img class="icon-medium" src="../../../assets/Component 39 – 1.svg" />
                                <label class="boschsansmedium pl-2 blue">Celular &nbsp;</label>
                                <label> {{ contact.cellphone | mask: '(00) 00000-0000' }} </label>
                            </div>
                        </div>
                        <!-- <div class="row" *ngFor="let channel of contact.lstAditionalChannel">
                            <div class="col col-12">
                                <img class="icon-medium" src="{{channel.icon}}" />
                                <label class="boschsansmedium pl-2 blue"> {{ channel.type | enum: "ContactType" }}
                                    &nbsp;</label>
                                <label> {{ channel.content }} </label>
                            </div>
                        </div> -->
                        <div class="row" >
                            <div class="col col-3">
                                <label class="boschsansmedium pl-2 blue"> Observações &nbsp;</label>
                            </div>     
                            <div class="col col-9">
                                <label style="white-space: pre-wrap;"> {{ contact.notes }} </label> 
                            </div>                                 
                                                  
                        </div>

                    </div>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <div class="row header-labels" #target>
        <mat-accordion class="col col-12">
            <mat-expansion-panel hideToggle [(expanded)]="expanded">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="boschsansmedium blue mb-0"><b>{{ lblChannel }}</b></label>
                        <img class="icon-medium" src="../../../assets/Component 38 – 1.svg" />
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form class="form-area" [formGroup]="formGroup">
                    <input type="hidden" formControlName="_id" />
                    <div class="row">
                        <div class="col col-12">
                            <label class="boschsansmedium mb-0 mt-4">Nome</label>
                            <input class="form-control" formControlName="name" placeholder="Nome do contato" />
                            <span *ngIf="noName" class="red">Campo obrigatório</span>
                            <span *ngIf="noChannel" class="red">Adicione um canal de contato abaixo</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-6">
                            <label class="boschsansmedium mb-0 mt-4">Telefone</label>
                            <input class="form-control placeholder-ic-phone" formControlName="phone"
                                placeholder="(00) 0000-0000" mask="(00) 0000-0000" (focusout)="checkPhone()" />
                            <span *ngIf="erroPhone" class="red">Digite um número válido</span>
                        </div>
                        <div class="col col-6">
                            <label class="boschsansmedium mb-0 mt-4">Celular</label>
                            <input class="form-control placeholder-ic-phone" formControlName="cellphone"
                                placeholder="(00) 00000-0000" mask="(00) 00000-0000" (focusout)="checkCellPhone()" />
                            <span *ngIf="erroCellPhone" class="red">Digite um número válido</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <label class="boschsansmedium mb-0 mt-4">E-mail</label>
                            <input type="email" class="form-control placeholder-ic-mail" formControlName="email"
                                placeholder="exemplo@servidor.com" (focusout)="checkEmail()"/>
                            <span *ngIf="erroEmail" class="red">Digite um e-mail válido</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <label class="boschsansmedium mb-0 mt-4">Observações</label>
                            <input class="form-control" formControlName="notes"
                                placeholder="Notas"/>
                        </div>
                    </div>
                    <!-- <div *ngIf="lstAditionalChannel.length > 0" class="painel-contact mt-3">
                        <div class="row row-painel">
                            <div class="header-painel-contact">
                                <div class="col col-12">
                                    <label class="text-center boschsansmedium">Canais de contatos</label>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col col-2">
                                <label class="boschsansmedium blue">Tipo</label>
                            </div>
                            <div class="col col-10">
                                <label class="boschsansmedium blue">Valor</label>
                            </div>
                        </div>
                        <div class="row text-center mb-2" *ngFor="let aditionalChannel of lstAditionalChannel">
                            <div class="col col-2">
                                <span> {{ aditionalChannel.type | enum: "ContactType" }} </span>
                            </div>
                            <div class="col col-10">
                                <span> {{ aditionalChannel.content }} </span>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="row">
                        <div class="col col-12 mt-4">
                            <a href="javascript:void(0);" (click)="toggleShowDiv('newchanel')">
                                <label class="boschsansmedium blue mb-0" style="cursor: pointer;">Inserir canal de contato</label>
                                <img class="icon-medium" src="../../../assets/Component 38 – 1.svg" />
                            </a>
                        </div>
                    </div>
                    <div [@slideInOut]="animationState" class="row newchanel mt-3">
                        <div class="col col-2">
                            <mat-label class="placeholder boschsansmedium blue mb-0">
                                Tipo
                            </mat-label>
                            <mat-select (selectionChange)="changingType()" formControlName="contactType">
                                <mat-option value="telefone">
                                    <img class="icon-medium" src="../../../assets/Component 39 – 1.svg" />
                                    Telefone
                                </mat-option>
                                <mat-option value="celular">
                                    <img class="icon-medium" src="../../../assets/Component 39 – 1.svg" />
                                    Celular
                                </mat-option>
                                <mat-option value="email">
                                    <img class="icon-medium" src="../../../assets/Component 40 – 1.svg" />
                                    E-mail
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col col-8">
                            <input class="form-control" formControlName="contactValue" placeholder="{{lblPlaceholder}}" />
                        </div>
                        <div class="button-area">
                            <button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
                                (click)="addChanel()" mat-flat-button>
                                Adicionar Canal
                            </button>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-3 mt-5 button-area" [style.visibility]="editionMode">
                            <p (click)="cancelEdition()">Cancelar Edição</p>                                                    
                        </div>                        
                        <div class="col-3 mt-5 button-area" [style.visibility]="editionMode">
                            <p (click)="removeContact()">Excluir Contato</p>                                                    
                        </div>                        
                        <div class="col-3 mt-5 button-area">
                            <button type="button"
                                class="mat-focus-indicator button-style mat-flat-button mat-button-base"
                                (click)="addContact()" mat-flat-button>
                                {{ lblbuton }}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>