import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';

export interface DialogData {
  startingDateMin: Date,
  startingDateMax: Date, 
  endingDateMin: Date, 
  endingDateMax: Date, 
  nextInvoiceDateMin: Date, 
  nextInvoiceDateMax: Date, 
  farm: String, 
  contractNumber: number 
}
@Component({
  selector: 'app-dialog-contract-list',
  templateUrl: './dialog-contract-list.component.html',
  styleUrls: ['./dialog-contract-list.component.scss']
})
export class DialogContractListComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  
  formGroup: FormGroup = new FormGroup({
    startingDateMin: new FormControl(this.data.startingDateMin),
    startingDateMax: new FormControl(this.data.startingDateMax),
    endingDateMin: new FormControl(this.data.endingDateMin),
    endingDateMax: new FormControl(this.data.endingDateMax),
    nextInvoiceDateMin: new FormControl(this.data.nextInvoiceDateMin),
    nextInvoiceDateMax: new FormControl(this.data.nextInvoiceDateMax),
    farm: new FormControl(this.data.farm),
    contractNumber: new FormControl(this.data.contractNumber),
  });

  
  constructor(
    public dialogRef: MatDialogRef<DialogContractListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clean(){
    this.formGroup = new FormGroup({
      startingDateMin: new FormControl(""),
      startingDateMax: new FormControl(""),
      endingDateMin: new FormControl(""),
      endingDateMax: new FormControl(""),
      nextInvoiceDateMin: new FormControl(""),
      nextInvoiceDateMax: new FormControl(""),
      farm: new FormControl(""),
      contractNumber: new FormControl(""),
    });
  }

  submit(){
    this.data.startingDateMin = this.formGroup.value.startingDateMin;
    this.data.startingDateMax = this.formGroup.value.startingDateMax;
    this.data.endingDateMin = this.formGroup.value.endingDateMin;
    this.data.endingDateMax = this.formGroup.value.endingDateMax;
    this.data.nextInvoiceDateMin = this.formGroup.value.nextInvoiceDateMin;
    this.data.nextInvoiceDateMax = this.formGroup.value.nextInvoiceDateMax;
    this.data.farm = this.formGroup.value.farm;
    this.data.contractNumber = this.formGroup.value.contractNumber;
  }

}
