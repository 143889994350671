<a href="javascript:void(0);" (click)="clean()" class="blue modal-title" mat-dialog-title>Limpar Filtros</a>
<div mat-dialog-content>
    <form class="form-area" [formGroup]="formGroup">
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Valor do Animal</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <span><b>Mínimo</b></span>
                <input class="form-control" currencyMask formControlName="valueMin" placeholder="Valor Mínimo" />
            </div>
            <div class="col col-6">
                <span><b>Máximo</b></span>
                <input class="form-control" currencyMask formControlName="valueMax" placeholder="Valor Máximo" />
            </div>
        </div>

        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Status dos Animais</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="cobrado">Cobrado</mat-checkbox>
            </div>
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="naoCobradoSempre">Não Será Cobrado (Nunca)</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                <mat-checkbox ngDefaultControl formControlName="naoCobradoFatura">Não Será Cobrado (Fatura)</mat-checkbox>
            </div>
        </div>

        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Data Última Leitura</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-6">
                <p class="mb-0"><span><b>De</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker1" formControlName="lastReadMin"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
            </div>
            <div class="col col-6">
                <p class="mb-0"><span><b>Até</b></span></p>
                <input class="form-datepicker" [matDatepicker]="picker2" formControlName="lastReadMax"
                    placeholder="Selecionar data" disabled />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            </div>
        </div>
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Parcela</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="parcel" placeholder="Parcela" />
            </div>
        </div>  
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Lote</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="batch" placeholder="Lote" />
            </div>
        </div>   
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Baia</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="pen" placeholder="Baia" />
            </div>
        </div>     
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Sisbov</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="sisbov" placeholder="Sisbov" />
            </div>
        </div>         
        <div class="row bck-grey">
            <div class="col col-11">
                <label class="my-2">Número Bosch</label>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col col-11">
                <input class="form-control" formControlName="boschNumber" placeholder="Número Bosch" />
            </div>
        </div>       
    </form>
</div>
<div mat-dialog-actions class="row">
    <div class="offset-4 col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
            (click)="submit()" [mat-dialog-close]="data">
            Aplicar Filtros
        </button>
    </div>
</div>
