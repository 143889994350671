import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API } from "src/app/shared/app.api";
import { tap } from "rxjs/internal/operators/tap";
import { map } from "rxjs/internal/operators/map";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"]
})
export class OverviewComponent implements OnInit {
  qtdoAtivo: string;
  qtdFaturamento: string;
  qtdAFaturar: string;
  qtdContestado: string;
  totalFaturar: string;
  qtdFinanceiro: string;
  qtdPerformance: string;

  constructor(private httpClient: HttpClient) {
    this.httpClient
      .get(`${API}/contracts/summary`)
      .pipe(
        map((x: any) => x.data),
        tap((x: any) => {
          this.qtdoAtivo = x.numActiveContracts;
          this.qtdFaturamento = x.numInvoicesInBillingStatus;
          this.qtdAFaturar = x.numInvoicesInToBillStatus;
          this.qtdContestado = x.numInvoicesInContestedStatus;
          this.totalFaturar = x.totalValueToBill;
          this.qtdFinanceiro = x.numFinancialContracts;
          this.qtdPerformance = x.numPerformanceModuleContracts;
        })
      )
      .subscribe();
  }

  ngOnInit() {}
}
