import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import {
  MatPaginatorModule,
  MatPaginatorIntl
} from "@angular/material/paginator";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from "@angular/material/table";
import { NavigationBarComponent } from "./base-layout/navigation-bar/navigation-bar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SvgIconService } from "./services/svg-icon.service";
import { PtBrMatPaginator } from "./shared/base-table/mat-paginator-intl";
import { MatTabsModule } from "@angular/material/tabs";
import { BillListComponent } from "./dashboard/bill-list/bill-list.component";
import { ContractListComponent } from "./dashboard/contract-list/contract-list.component";
import { FooterComponent } from "./base-layout/footer/footer.component";
import { ToastrModule } from "ngx-toastr";
import { LoadingComponent } from "./shared/loading/loading.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoadingService } from "./shared/loading/loading.service";
import { OverviewComponent } from "./dashboard/overview/overview.component";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { InvoiceComponent } from './invoice/invoice.component';
import { AnimalComponent } from './invoice/animal/animal.component';
import { HistoryComponent } from './invoice/history/history.component';
import { CommentsComponent } from './invoice/comments/comments.component';
import { AccessGuard } from './shared/guards/access.guard';
import { initializer } from './shared/utils/app-init';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { ContractComponent } from './contract/contract.component';
import { OverviewContractComponent } from './contract/overview-contract/overview-contract.component';
import { ContactComponent } from './contract/contact/contact.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BillComponent } from './contract/bill/bill.component';
import { DialogBillListComponent } from './dashboard/dialog-bill-list/dialog-bill-list.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { DialogContractListComponent } from './dashboard/dialog-contract-list/dialog-contract-list.component';
import { DialogBillContractListComponent } from './contract/dialog-bill-contract-list/dialog-bill-contract-list.component';
import { DialogInvoiceStateInvoiceInputComponent } from 'src/app/invoice/dialog-invoice-state-invoice-input/dialog-invoice-state-invoice-input.component';
import { DialogInvoiceValueEditionComponent } from 'src/app/invoice/dialog-invoice-value-edition/dialog-invoice-value-edition.component';
import { DialogAnimalListComponent } from './invoice/dialog-animal-list/dialog-animal-list.component';
import { DialogChangeStatusComponent } from './invoice/dialog-change-status/dialog-change-status.component';

import { ServiceComponent } from './contract/service/service.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RegisterContractComponent } from './contract/register-contract/register-contract.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BaseRequests } from "./shared/base-requests";

registerLocaleData(localeEn);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    DashboardComponent,
    OverviewComponent,
    BillListComponent,
    ContractListComponent,
    FooterComponent,
    LoadingComponent,
    AnimalComponent,
    InvoiceComponent,
    HistoryComponent,
    CommentsComponent,
    ContractComponent,
    OverviewContractComponent,
    ContactComponent,
    BillComponent,
    DialogBillListComponent,
    DialogContractListComponent,
    DialogBillContractListComponent,
    DialogAnimalListComponent,
    DialogInvoiceValueEditionComponent,
    DialogInvoiceStateInvoiceInputComponent,
    DialogChangeStatusComponent,
    ServiceComponent,
    RegisterContractComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-center"
    }),
    SharedModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    KeycloakAngularModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot(options),
    MatAutocompleteModule,
    ScrollingModule
  ],
  providers: [
    LoadingService,
    SvgIconService,
    BaseRequests,
    { provide: MatPaginatorIntl, useClass: PtBrMatPaginator },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    AccessGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
