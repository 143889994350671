import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  idInvoice: string,
  chargedValue: number,
  comment: string,
}
@Component({
  selector: 'app-dialog-invoice-value-edition',
  templateUrl: './dialog-invoice-value-edition.component.html',
  styleUrls: ['./dialog-invoice-value-edition.component.scss']
})
export class DialogInvoiceValueEditionComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogInvoiceValueEditionComponent>,
    private _api: ApiService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      chargedValue: new FormControl(this.data.chargedValue),
      comment: new FormControl(this.data.comment), 
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clean(){
    this.formGroup = new FormGroup({
      chargedValue: new FormControl(""),
      comment: new FormControl(""),   
    });  
  }

  submit(){
    if(this.formGroup.value.chargedValue !== null && this.formGroup.value.chargedValue >= 0 && this.formGroup.value.comment){
      this._api.updateInvoiceChargedValue(this.data.idInvoice, this.formGroup.value.chargedValue, this.formGroup.value.comment).subscribe(response => this.toastr.success("O valor cobrado da fatura foi editado!"));
      this.dialogRef.close();
    } else {
      this.toastr.error("Campos obrigatórios não foram preenchidos.")
    }
    
  }

}
