<div class="base-table-container container-fluid">
  <div class="row header-labels">
    <div class="row col-12 pr-0">
      <div class="col-4 no-padding">
        <label class="grid-title">LISTA DE {{ grid }}</label>
      </div>
      <div class="offset-5 col-3 button-area">
        <a href="javascript:void(0);" class="blue boschsansregular" (click)="openDialogFilter(grid)">
          <img class="icon-medium pr-2" src="../../../assets/filter.png" />
          <span>Filtros</span>
        </a>
      </div>
    </div>

    <div class="row col-12">
      <a href="/registerContract" class="blue boschsansregular">
        <mat-icon class="icon-medium" svgIcon="svg-new-file"></mat-icon>
        Novo Contrato
      </a>
    </div>
  </div>
  <div class="row">
    <table (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
      class="base-table mat-elevation-z8">
      <ng-container *ngFor="let index of indexes" matColumnDef="{{ index.property }}">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> {{ index.label }} </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element[index.property] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="startingDate">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> DATA DE INÍCIO </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startingDate | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="endingDate">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> DATA DE VENC. </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.endingDate | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nextInvoiceDate">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> PRÓXIMA FATURA </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.nextInvoiceDate | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nextBill">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> PRÓXIMA FATURA </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.nextBill | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> DATA </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceDate | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceClosingDate">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> FECHAMENTO </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceClosingDate | date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceValue">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> VALOR CALCULADO</b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceValue | currency:'BRL':'symbol':'1.2-2':'br' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceChargedValue">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> VALOR COBRADO</b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceChargedValue | currency:'BRL':'symbol':'1.2-2':'br' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceTypeEnum">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> TIPO </b>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoiceTypeEnum }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceStatusEnum">
        <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
          <b> STATUS </b>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-list>
            <mat-chip class="mat-chip-style" [style.color]="getStatusTextColor(element.invoiceStatusEnum)"
              [style.border-color]="getStatusBorderColor(element.invoiceStatusEnum)"
              [style.border]="setStatusBorder(element.invoiceStatusEnum)"
              [style.background-color]="getStatusChipColor(element.invoiceStatusEnum)">
              {{  element.invoiceStatusEnum }}
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [ngClass]="{'hide-rows': this.showRows}" mat-row *matRowDef="let row; columns: displayedColumns" class="order-by-label example-element-row"
          (click)="details(row)"></tr>
    </table>
  </div>
  <div class="row">
    <mat-paginator class="paginator-style" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"
      (page)="searchOrderFilterOrPaginate($event)">
    </mat-paginator>
  </div>
</div>