import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  url: 'https://auth.beefperformance.com/auth',
  realm: 'Billing',
  clientId: 'billing-client',
  credentials: {
    secret: '87a3be2b-ea7f-4a37-9460-38ba87817163'
  }
};

export const environment = {
  production: true,
  keycloak: keycloakConfig
};