import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  
  private statusSource = new BehaviorSubject<any>('');
  status = this.statusSource.asObservable()
  
  private idFaturaSource = new BehaviorSubject<any>('');
  idFatura = this.idFaturaSource.asObservable()
  
  private numContractSource = new BehaviorSubject<any>('');
  numContract = this.numContractSource.asObservable()
  
  private idContractSource = new BehaviorSubject<any>('');
  idContract = this.idContractSource.asObservable()

  private invoiceAlertSource = new BehaviorSubject<any>('');
  invoiceAlert = this.invoiceAlertSource.asObservable()
  
  private idFarmSource = new BehaviorSubject<any>('');
  idFarm = this.idFarmSource.asObservable()
  
  private penChange = new Subject<boolean>();


  constructor() { }

  setpenChange(status: boolean){
    console.log('set pen change');
    this.penChange.next(status);
  }

  getpenChange(){
    return this.penChange;
  }

  setStatus(status: any){
    this.statusSource.next(status)
  }

  setIdFatura(idFatura: string){
    this.idFaturaSource.next(idFatura);
  }
  
  setNumContract(numContract: string){
    this.numContractSource.next(numContract);
  }

  setIdContract(idContract: string){
    this.idContractSource.next(idContract);
  }

  setInvoiceAlert(invoiceAlert: boolean){
    this.invoiceAlertSource.next(invoiceAlert);
  }

  setIdFarm(idFarm: string){
    this.idFarmSource.next(idFarm);
  }


}
