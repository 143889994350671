import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  //url params
  private invoiceId: string = "";

  //Form
  comment = new FormGroup({
    title: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required)

  });

  //helpers
  public newComment: boolean = false;
  public commentList = [];
  public isUpdate: boolean = false;
  private commentId: string;

  constructor(private api: ApiService) {
    this.invoiceId = window.location.pathname.split("/")[2];
    this.getComments();
  }

  ngOnInit(): void {

  }

  private getComments(): void {
    this.api.getComments(this.invoiceId).subscribe(data => {
      this.commentList = data;
    });
  }

  public handleNewComment(): void {
    if (this.comment.valid) {
      this.api.newComments(this.invoiceId, this.comment.value).subscribe(() => this.getComments())
      this.hideNewComment();
    }
  }

  public handleUpdateComment(): void {
    if (this.comment.valid) {
      this.api.updateComments(this.invoiceId, this.commentId, this.comment.value).subscribe(() => this.getComments())
      this.hideNewComment();
    }
  }

  public handleDeleteComment(): void {
    if (this.isUpdate && this.commentId) {
      this.api.deleteComments(this.invoiceId, this.commentId).subscribe(() => this.getComments())
      this.hideNewComment();
    }
  }

  public getCommentUpdate(comment): void {
    this.isUpdate = true;
    this.comment.get('title').setValue(comment.title);
    this.comment.get('content').setValue(comment.content);
    this.commentId = comment._id;
    this.showNewComment();
  }

  public showNewComment(): void {
    this.newComment = true;
  }

  public hideNewComment(): void {
    this.newComment = false;
    this.comment.reset();
    this.commentId = "";
    this.isUpdate = false;
  }



}
