import { NgModule } from "@angular/core";
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BillListComponent } from "./dashboard/bill-list/bill-list.component";
import { ContractListComponent } from "./dashboard/contract-list/contract-list.component";
import { AnimalComponent } from './invoice/animal/animal.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { HistoryComponent } from './invoice/history/history.component';
import { CommentsComponent } from './invoice/comments/comments.component';
import { AccessGuard } from './shared/guards/access.guard';
import { ContractComponent } from './contract/contract.component';
import { BillComponent } from './contract/bill/bill.component';
import { ServiceComponent } from './contract/service/service.component';
import { RegisterContractComponent } from './contract/register-contract/register-contract.component';

export const routes: Routes = [
  {
    path: "home",
    component: DashboardComponent,
    children: [
      {
        matcher: billListOrDefaultRoute,
        component: BillListComponent
      },
      {
        path: "contracts",
        component: ContractListComponent
      }
    ],
    canActivate: [AccessGuard],
    data: { roles: ['admin'] }
  },
  {
    path: "invoice/:id",
    component: InvoiceComponent,
    children: [
      {
        matcher: animalListOrDefaultRoute,
        component: AnimalComponent,
      },
      {
        path: "histories",
        component: HistoryComponent
      },
      {
        path: "comments",
        component: CommentsComponent
      }
    ],
    canActivate: [AccessGuard],
    data: { roles: ['admin'] }
  },
  {
    path: "contract/:id",
    component: ContractComponent,
    children: [
      {
        matcher: billListOrDefaultRoute,
        component: BillComponent,
      },
      {
        path: "services",
        component: ServiceComponent
      }
    ],
    canActivate: [AccessGuard],
    data: { roles: ['admin'] }
  },
  {
    path: "registerContract",
    component: RegisterContractComponent,
    children: [],
    canActivate: [AccessGuard],
    data: { roles: ['admin'] }
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/home",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// Matchers
export function billListOrDefaultRoute(url: UrlSegment[]) {
  if (
    url &&
    (url.length === 0 || url[0].path === "" || url[0].path.includes("bills"))
  ) {
    return { consumed: url };
  } else {
    return null;
  }
}

export function animalListOrDefaultRoute(url: UrlSegment[]) {
  if (
    url &&
    (url.length === 0 || url[0].path === "" || url[0].path.includes("animals"))
  ) {
    return { consumed: url };
  } else {
    return null;
  }
}
