<div *ngIf="this.hasAFaturar" class="bkg-alert container col-xl-6 offset-xl-3 col-8 offset-2">
    <div class="row pt-4 pl-2">
        <div class="col col-1 pt-1 pl-4 mr-3">
            <mat-icon class="icon-warning" svgIcon="svg-icon-warning"></mat-icon>
        </div>
        <div class="col">
            <span class="boschsansbold">AGUARDANDO FATURAMENTO</span> <br />
            <span class="boschsansregular">Existem cobranças aguardando faturamento neste contrato.</span>
        </div>
    </div>
</div>

<div class="root-container sub-container">
    <div class="col-xl-8 offset-xl-2 col-10 offset-1">
        <section id="row-overview">
            <div class="row mt-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/home/contracts">Contratos</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contrato #{{ contrato }}</li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                <div class="col col-md-10 vg-label">
                    <label class="subtitle">Contrato <strong>#{{ contrato }}</strong></label>
                </div>
            </div>
        </section>
        <div class="bkg-vg">
            <div class="row details-vg pt-4">
                <div class="col col-vg">
                    <span class="boschsansbold">ÚLTIMO PERÍODO {{ lastPeriod | date:'dd/MM/yyyy' }}</span>
                </div>
            </div>
            <div class="row pl-3 pb-2">
                <div class="col col-3 col-vg">
                    <span class="label-vg">Nº DE ANIMAIS MONITORADOS</span> <br />
                    <span class="sbtxt-vg boschsansregular">{{ qtdAnimals }}</span>
                </div>
                <div class="col col-3 ml-md-2 col-vg">
                    <span class="label-vg">SERVIÇOS</span> <br />
                    <span class="sbtxt-vg boschsansregular">{{ service }}</span>
                </div>
                <div class="col ml-md-2 col-vg">
                    <span class="label-vg">VALOR FATURADO</span>
                    <p class="sbtxt-vg boschsansregular">{{ vlrFaturado | currency:'BRL':'symbol':'1.2-2':'br' }}</p>
                </div>
                <div class="col col-4 ml-md-2 pl-5 col-vg">
                    <div class="row">
                        <!-- <div class="col col-2 pt-2 pr-0">
                            <mat-icon class="icon-img" svgIcon="svg-icon-warning"></mat-icon>
                        </div> -->
                        <div class="col col-8 pl-0  ">
                            <span class="label-vg">ÚLTIMO FATURAMENTO</span>
                            <p class="sbtxt-vg boschsansregular">{{ dtLastBill | date:'dd/MM/yyyy' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-vg">
            <div class="row pt-md-3">
                <div class="col ml-md-3 vg border-0">
                    <label class="blue"><strong>CONTRATUAL</strong></label>
                </div>
                <div class="col ml-md-2 vg">
                    <label class="blue"><strong>INFORMAÇÕES ADICIONAIS</strong></label>
                </div>
                <div class="col ml-md-2">
                </div>
            </div>
            <div class="row row-height">
                <div class="col ml-md-3 vg border-0">
                    <span><b>Fazenda</b></span> <br />
                    <span class="">{{ fazenda }}</span> <br />
                </div>
                <div class="col ml-md-2 vg">
                    <span><b>Data de Criação do Contrato</b></span> <br />
                    <span class="">{{ dtCriacao | date:'dd/MM/yyyy' }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Faturas em Aberto</b></span>
                    <p><label *ngFor="let fatura of faturasAberto"><a href="/invoice/{{ fatura._id }}">#{{ fatura.code }}&nbsp;</a></label></p>
                </div>
            </div>
            <div class="row row-height">
                <div class="col ml-md-3 vg border-0">
                    <span><b>CNPJ / CPF</b></span> <br />
                    <span class="">{{ cpfcnpj }}</span> <br />
                </div>              
                <div class="col ml-md-2 vg">
                    <span><b>Data de Início (Vigência)</b></span> <br />
                    <span class="">{{ dtInicio | date:'dd/MM/yyyy' }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Sistemas Vinculados</b></span>
                    <cdk-virtual-scroll-viewport [itemSize]="18" class="system-viewport">
                        <div *cdkVirtualFor="let system of linkedSystems" class="system-item">{{system}}</div>
                    </cdk-virtual-scroll-viewport>
                    <!--<p class="">{{ linkedSystems }}</p>-->
                </div>
            </div>
            <div class="row">
                <div class="col ml-md-3 vg border-0">
                    <span><b>Inscrição Estadual</b></span> <br />
                    <span class="">{{ stateRegistration }}</span> <br />
                </div>
                <div class="col ml-md-2 vg">
                    <span><b>Data de Vencimento (Vigência)</b></span> <br />
                    <span class="">{{ dtVencimento | date:'dd/MM/yyyy' }}</span> <br />
                </div>
                <div class="col ml-md-2 pt-3 vg border-0">
                </div>
            </div>
            <div class="row">
                <div class="col ml-md-3 pt-3 vg border-0">
                    <span><b>Tipo de Contrato</b></span> <br />
                    <span class="">{{ tipo }}</span> <br />                    
                </div>  
                <div class="col ml-md-2 pt-3 vg">
                    <span><b>Próximo Faturamento</b></span> <br />
                    <span class="">{{ dtProximo | date:'dd/MM/yyyy' }}</span> <br />
                </div>
                <div class="col ml-md-2 pt-3 vg border-0">
                </div>
            </div>
            <div class="row">
                <div class="col ml-md-3 pt-3 vg border-0">
                    <span><b>Vigência</b></span> <br />
                    <span class="">{{ vigencia }}</span> <br />
                </div>
                <div class="col ml-md-2 pt-3 vg">

                </div>
                <div class="col ml-md-2">
                </div>
            </div>
            <app-contact></app-contact>
        </div>
    </div>
</div>