import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseTableComponent } from 'src/app/shared/base-table/base-table.component';
import { API } from 'src/app/shared/app.api';
import { TableIndex } from "src/app/domain/interfaces/table-index.interface";

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {
  @ViewChild(BaseTableComponent, { static: true })
  baseTable: BaseTableComponent;

  indexes: TableIndex[] = [
    { label: "CONTRATO", property: "numContract" },
    { label: "SERVIÇOS", property: "numServices" },
    { label: "FAZENDA", property: "farmName" },
    { label: "CLIENTE", property: "clientName" }
  ];

  specialColumnsIndexes: TableIndex[] = [
    { label: "DATA DE INÍCIO", property: "startingDate" },
    { label: "DATA DE VENC.", property: "endingDate" },
    { label: "PRÓXIMA FATURA", property: "nextInvoiceDate" }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.baseTable.setupTable(
      `${API}/contracts`,
      this.indexes,
      'BillsAndContracts',
      "contract",
      "CONTRATOS",
      this.specialColumnsIndexes
    );
    this.baseTable.loadTable();
  }

}
