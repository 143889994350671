import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BoschBilling';

  constructor(
    private router: Router,
    private auth: AuthService,
  ) {  }

  ngOnInit() {
    // check if user it is logged
    // this.auth.isLogged ? this.router.navigate(['/home']) : console.log('Not logged!');
  }
}
