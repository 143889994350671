import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent } from "src/app/shared/base-table/base-table.component";
import { TableIndex } from "src/app/domain/interfaces/table-index.interface";
import { API } from "src/app/shared/app.api";

@Component({
  selector: "app-bill-list",
  templateUrl: "./bill-list.component.html",
  styleUrls: ["./bill-list.component.scss"]
})
export class BillListComponent implements OnInit {
  @ViewChild(BaseTableComponent, { static: true })
  baseTable: BaseTableComponent;

  indexes: TableIndex[] = [
    { label: "FATURAMENTO", property: "numInvoice" },
    { label: "CONTRATO", property: "numContract" },
    { label: "FAZENDA", property: "contractOwner" },
  ];

  specialColumnsIndexes: TableIndex[] = [
    { label: "DATA", property: "invoiceDate" },
    { label: "VALOR CALCULADO", property: "invoiceValue" },
    { label: "VALOR COBRADO", property: "invoiceChargedValue" },
    { label: "TIPO", property: "invoiceTypeEnum" },
    { label: "STATUS", property: "invoiceStatusEnum" }
  ];

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.baseTable.setupTable(
      `${API}/invoices`,
      this.indexes,
      'BillsAndContracts',
      "invoice",
      "FATURAMENTOS",
      this.specialColumnsIndexes
    );

    // this.baseTable.reorganizeColumns([
    //   "FATURAMENTO",
    //   "CONTRATO",
    //   "CONTRATANTE",
    //   "DATA",
    //   "VALOR",
    //   "TIPO",
    //   "STATUS"
    // ]);

    this.baseTable.loadTable();
  }
}
