import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  stateInvoiceNumber: string,
}
@Component({
  selector: 'app-dialog-invoice-state-invoice-input',
  templateUrl: './dialog-invoice-state-invoice-input.component.html',
  styleUrls: ['./dialog-invoice-state-invoice-input.component.scss']
})
export class DialogInvoiceStateInvoiceInputComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogInvoiceStateInvoiceInputComponent>,
    private _api: ApiService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      stateInvoiceNumber: new FormControl(""),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clean(){
    this.formGroup = new FormGroup({
      stateInvoiceNumber: new FormControl(""),
    });  
  }

  submit(){
    if(this.formGroup.value.stateInvoiceNumber){
      this.data.stateInvoiceNumber = this.formGroup.value.stateInvoiceNumber;
      this.dialogRef.close(this.data);
    } else {
      this.toastr.error("Campos obrigatórios não foram preenchidos.");
    }
  }

}
