import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class SvgIconService {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      "svg-undo",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/undo.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-minus-circle",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/minus-circle.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-receipt_2",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/receipt_2.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-user",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/User.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-user2",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/Component 49 – 1.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-external-link-square-alt",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/external-link-square-alt.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-exclamation-circle",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/exclamation-circle.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-logout",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/logout.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-icon-warning",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/icon_warning.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-ic-pen",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/ic-pen.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-clear",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/clear.svg")
    );    
    iconRegistry.addSvgIcon(
      "svg-mail",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/Component 40 – 1.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-coin",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/Component 46 – 1.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-phone",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/Component 39 – 1.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-plus",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/Component 38 – 1.svg")
    );
    iconRegistry.addSvgIcon(
      "svg-new-file",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/ic-new-file.svg")
    );
    iconRegistry.addSvgIcon(
      "filter",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/filter.png") 
    );
    iconRegistry.addSvgIcon(
      "ic-new-doc",
      sanitizer.bypassSecurityTrustResourceUrl("../../../assets/ic-new-doc.svg") 
    );    
  }
}
