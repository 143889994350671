import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '../shared/loading/loading.service';
import { LoadingComponent } from '../shared/loading/loading.component';
import { BehaviorSubject } from 'rxjs';
import { OverviewInvoiceComponent } from './overview-invoice/overview-invoice.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @ViewChild(LoadingComponent, {}) loadingComponent: LoadingComponent;
  @ViewChild(OverviewInvoiceComponent, { static: false }) overView: OverviewInvoiceComponent;

  routeVisible = false;
  tabIndex = 0;
  idInvoice: string;
  loadingBehaviorSubject: BehaviorSubject<boolean>;
  status: string;

  constructor(
    private router: Router,
    private updateRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.idInvoice = this.route.snapshot.params['id'];
  }

  ngOnDestroy() {
    this.loadingService.billsAndContractsLoading.complete();
  }

  ngAfterViewInit() {
    this.changeOnLoadIfAlreadyInHistories(this.router.url);
    this.watchLoading();
  }

  changeOnLoadIfAlreadyInHistories(url: string) {
    if (url.includes("histories")) {
      this.tabIndex = 1;
      this.updateRef.detectChanges();
    }
    if (url.includes("comments")) {
      this.tabIndex = 2;
      this.updateRef.detectChanges();
    }
  }

  tabChange(tabNumber) {
    switch (tabNumber) {
      case 0:
        return this.router.navigate([`invoice/${this.idInvoice}/animals`]);
      case 1:
        return this.router.navigate([`invoice/${this.idInvoice}/histories`]);
      case 2:
        return this.router.navigate([`invoice/${this.idInvoice}/comments`]);
    }
  }

  watchLoading() {
    this.loadingService.billsAndContractsLoading.subscribe(x => {
      if (x === true) {
        this.loadingComponent.enabled = true;
      } else {
        this.loadingComponent.enabled = false;
      }
    });
  }

  showRoute() {
    this.routeVisible = true;
  }
}
