import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { TableIndex } from 'src/app/domain/interfaces/table-index.interface';
import { API } from 'src/app/shared/app.api';
import { BaseTableInvoiceComponent } from 'src/app/shared/base-table-invoice/base-table-invoice.component';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.scss']
})
export class AnimalComponent implements OnInit {
  @ViewChild(BaseTableInvoiceComponent, { static: true }) baseTable: BaseTableInvoiceComponent;
  status: string;

  constructor(private _invoice: InvoiceService) { }

  queryParam: string = "";
  indexes: TableIndex[] = [
    { label: "Nº BOSCH", property: "boschNumber" },
    { label: "SISBOV", property: "sisbov" },
    { label: "BAIA", property: "penCode" },
    { label: "LOTE", property: "batchName" },
  ];

  specialColumnsIndexes: TableIndex[] = [
    { label: "ÚLTIMA LEITURA", property: "lastRead" },
    { label: "VALOR", property: "value" },
    { label: "PARCELA", property: "parcel" },
    { label: "STATUS", property: "status" },
    { label: " ", property: "missing" }
  ];

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this._invoice.idFatura.subscribe(b => {
      if(this.queryParam === ""){
        this.queryParam = b;
      }      
    });

    this._invoice.status.subscribe(b => {
      if(!this.status){
        this.status = b;
        this.getAnimals(this.queryParam);
      }   
    });

  }

  getAnimals(queryParam: string) {
    if (!queryParam) { return; }
    this.baseTable.setupTable(
      `${API}/invoices/${queryParam}/animals`,
      this.indexes,
      'Invoice',
      this.specialColumnsIndexes,
      this.status,
      'Animals'
    );
    
    this.baseTable.loadTable();  

  }
}
