
<div class="container-fluid">
    <div class="row">
      <app-overview-invoice (isLoaded)="this.showRoute()" class="strech-overview"></app-overview-invoice>
    </div>
    <div class="row tab-row">
      <div class="col-xl-8 offset-xl-2 col-10 offset-1 tab-col">
        <mat-tab-group
          [selectedIndex]="tabIndex"
          (selectedIndexChange)="tabChange($event)"
        >
          <mat-tab label="Animais"> </mat-tab>
          <mat-tab label="Histórico"> </mat-tab>
          <mat-tab label="Comentários"> </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  
    <div class="row undertabs-row" *ngIf="this.routeVisible">
      <app-loading></app-loading>
      <div class="col-xl-8 offset-xl-2 col-10 offset-1 lists-area">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>