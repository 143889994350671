<div class="container-fluid" style="padding: 0;">
  <div class="bkg-vg">
    <div class="container col-xl-8 offset-xl-2 col-10 offset-1">
      <div class="row">
        <div class="set-vg">
          <span i18n>Visão Geral</span>
        </div>
      </div>
      <div class="row vg-row">
        <div class="col h-25 vg-label">
          <span>CONTRATOS ATIVOS</span>
          <p class="vg-p">{{ qtdoAtivo }}</p>
          <br />
          <span>A FATURAR</span>
          <p class="vg-p">{{ qtdAFaturar }}</p>
        </div>
        <div class="col ml-md-5 h-25 vg-label">
          <span>EM ANÁLISE</span>
          <p class="vg-p">{{ qtdFaturamento }}</p>
          <br />
          <span>CONTESTADOS</span>
          <p class="vg-p">{{ qtdContestado }}</p>
        </div>
        <div class="col ml-md-5 h-25 vg-label">
          <span>TOTAL À FATURAR</span>
          <p class="vg-p">{{ totalFaturar | currency:'BRL':'symbol':'1.2-2':'br' }}</p>
          <br />
          <span>&nbsp;</span>
          <p class="vg-p">&nbsp;</p>
        </div>
        <div class="col ml-md-5 h-25 vg-label">
          <span>CONTRATOS FINANCEIROS</span>
          <p class="vg-p">{{ qtdFinanceiro }}</p>
          <span>CONTRATOS MÓDULO PERFORMANCE</span>
          <p class="vg-p">{{ qtdPerformance }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
