import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { API } from '../../shared/app.api';
import { ToastrService } from 'ngx-toastr';
import { map, debounceTime, startWith } from 'rxjs/operators';
import { Router } from "@angular/router";

@Component({
  selector: 'app-register-contract',
  templateUrl: './register-contract.component.html',
  styleUrls: ['./register-contract.component.scss']
})
export class RegisterContractComponent implements OnInit {
  formGroup: FormGroup;
  farmFormControl: FormControl;
  farmList: any = [];
  filteredOptions: any = [];
  farmId: string;
  serviceList: any = [];
  contactList: any = [];
  triggerEmptyContactList: boolean = false;
  contractRegisterFirstScreen: boolean = true;

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.farmFormControl = new FormControl("", [
      Validators.required,
      this.validatorFarmName()
    ]);
    this.formGroup = new FormGroup({
      startingDate: new FormControl("", Validators.required),
      endingDate: new FormControl("", Validators.required),
      clientName: new FormControl("", Validators.required),
      stateRegistration: new FormControl(""),
      farmDocument: new FormControl("", Validators.required),
      farm: this.farmFormControl,
    });
    this.observableRequest();
    this.filteredOptions = this.farmFormControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

   private validatorFarmName() : ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
     
      if(this.farmList.find((farm) => farm.name === control.value ))
      {
        return null;
      }
      return {'teste':true};
    };
  }

  submit() {
    if (this.formGroup.valid) {
      const selectedFarm = this.farmList.find(farm => farm.name === this.formGroup.value.farm);
      let body = {
        startingDate: this.formGroup.value.startingDate,
        endingDate: this.formGroup.value.endingDate,
        clientName: this.formGroup.value.clientName,
        stateRegistration: this.formGroup.value.stateRegistration,
        farmDocument: this.formGroup.value.farmDocument,
        farmId: selectedFarm._id,
        services: this.serviceList,
        contacts: this.contactList,
      };
      return this.http
        .post(`${API}/contract`, body, { observe: 'response' })
        .pipe(
          debounceTime(500),
          map(response => {
            this.toastr.success("O contrato foi cadastrado!");
            if (response.status === 200){
              this.clearForm();
              this.router.navigate(['/home/contracts']);
            }
            else
              this.toastr.error("O contrato não foi cadastrado.");
            return response;
          }),
        )
        .subscribe(async b => {
          
        });
    } else {
      this.toastr.error("O contrato não foi cadastrado. Campos preenchidos incorretamente.");
    }
  }

  async observableRequest() {
    let farmUrl = `${API}/farms`;

    return this.http
      .get(farmUrl)
      .pipe(
        debounceTime(500),
        map(response => response),
      )
      .subscribe(async b => {
        if (b['data'] && b['data'].length > 0) {
          this.farmList = b['data'];
        }
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.farmList.filter(farm => farm.name.toLowerCase().includes(filterValue));
  }


  setFarmId(){
    const selectedFarm = this.farmList.find(farm => farm.name === this.formGroup.value.farm);
    this.farmId = selectedFarm._id;
  }

  updateServicesList(serviceList: any){
    this.serviceList = serviceList;
  }

  clearForm(){
    this.farmFormControl = new FormControl("", [
      Validators.required,
      this.validatorFarmName()
    ]);
    this.formGroup = new FormGroup({
      startingDate: new FormControl("", Validators.required),
      endingDate: new FormControl("", Validators.required),
      clientName: new FormControl("", Validators.required),
      stateRegistration: new FormControl(""),
      farmDocument: new FormControl("", Validators.required),
      farm: this.farmFormControl,
    });
    this.serviceList = [];
    this.contactList = [];
    this.farmId = "";
    this.triggerEmptyContactList = !this.triggerEmptyContactList;
  }

  updateContactList(newContactList){
    let contacts: any[] = [];

    newContactList.map((item, index) => {
      let channels: any[] = [];

      channels.push({
        content: item.phone,
        type: "telefone"
      })

      channels.push({
        content: item.cellphone,
        type: "celular"
      })

      channels.push({
        content: item.email,
        type: "email"
      })

      channels.concat(item.lstAditionalChannel)
      contacts.push({
        name: item.name,
        contactChannels: channels,
        notes: item.notes
      })
    });
    this.contactList = contacts;
  }

  goToSecondScreen(){
    if (this.formGroup.valid) {
    this.contractRegisterFirstScreen = false;
    } else {
      this.toastr.error("Campos preenchidos incorretamente.");
    }
  }

  goToFirstScreen(){
    this.contractRegisterFirstScreen = true;
  }
}
