import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BaseRequests {
    constructor(private http: HttpClient) {

    }

    public async getRequest(url) {
        return new Promise<any>((resolve, reject) => {
            this.http.get(url).subscribe((result: any) => {
                return resolve(result);
            }, (error: any) => {
                reject(error);
            })
        })
    }
}