import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TableIndex } from "src/app/domain/interfaces/table-index.interface";
import { MatTableDataSource } from "@angular/material/table";
import { FormGroup, FormControl } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "../loading/loading.service";
import { tap, delay, map, finalize, debounceTime } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { API } from "../app.api";
import { InvoiceService } from "src/app/services/invoice.service";
import { SvgIconService } from "src/app/services/svg-icon.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogAnimalListComponent } from "src/app/invoice/dialog-animal-list/dialog-animal-list.component";
import { DialogChangeStatusComponent } from "src/app/invoice/dialog-change-status/dialog-change-status.component";
import { ApiService } from "src/app/services/api.service";
import { BaseRequests } from "../base-requests";

@Component({
  selector: "app-base-table-invoice",
  templateUrl: "./base-table-invoice.component.html",
  styleUrls: ["./base-table-invoice.component.scss"],
})
export class BaseTableInvoiceComponent implements OnInit {
  @ViewChild(MatPaginator, {}) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public showRows: boolean = false;
  public sortDefault: boolean = false;
  urlQueryParams = "";
  filterObject = {
    valueMin: "",
    valueMax: "",
    cobrado: false,
    naoCobradoSempre: false,
    naoCobradoFatura: false,
    lastReadMin: "",
    lastReadMax: "",
    batch: "",
    pen: "",
    parcel: "",
    sisbov: "",
    boschNumber: "",
    status: "",
  };

  indexes: TableIndex[] = [];
  specialColumnsIndexes: TableIndex[] = [];

  displayedColumns: string[] = [];

  orderByOptions: string[] = [undefined];
  filterOptions: string[] = [undefined];

  listStatus: any[] = [];

  loadingSubjectName: string;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  formGroup: FormGroup = new FormGroup({
    searchControl: new FormControl(""),
    orderControl: new FormControl(""),
    filterControl: new FormControl(""),
  });
  grid: string;
  baseUrl: string;
  offset = 0;
  limit = 10;
  selectedRows = 0;
  showStatus = false;
  idFatura: string;
  changed: boolean = false;
  status: boolean = true;

  // MatPaginator Inputs
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  // Hardcoded messages
  MESSAGE_STATUS_CHANGE =
    "Deseja mesmo aplicar essa alteração para a fatura inteira?";
  TITLE_STATUS_CHANGE = "Alterar status";

  constructor(
    private http: HttpClient,
    private updateRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private _invoice: InvoiceService,
    private iconService: SvgIconService,
    private _api: ApiService,
    public dialog: MatDialog,
    private baseRequests: BaseRequests
  ) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (sortHeaderId === "invoiceStatusEnum") {
        return;
      }
      const value: any = data[sortHeaderId];
      return typeof value === "string" ? value.toLowerCase() : value;
    };

    this._invoice.idFatura.subscribe((b) => {
      this.idFatura = b;
    });
  }

  ngOnDestroy() {}

  setupTable(
    baseUrl: string,
    indexes: TableIndex[],
    loadingSubjectName: string,
    specialColumnsIndexes?: TableIndex[],
    status?: string,
    grid?: string
  ) {
    this.grid = grid;
    this.baseUrl = baseUrl;
    this.indexes = indexes;

    this.loadingSubjectName = loadingSubjectName;

    let columns = [];
    let columnsFilter = [];
    columns = this.indexes.map((x) => x.property);
    columnsFilter = this.indexes.map((x) => x.label);

    if (specialColumnsIndexes) {
      this.specialColumnsIndexes = specialColumnsIndexes;
      columns = columns.concat(specialColumnsIndexes.map((x) => x.property));
      columnsFilter = columnsFilter.concat(
        specialColumnsIndexes.map((x) => x.label)
      );
    }

    columnsFilter.forEach((x) => {
      this.filterOptions.push(x);
    });

    if (status === "em análise") {
      this.displayedColumns = ["select"];
      this.status = false;
    }

    if (grid == "Animals") {
      this.listStatus = [
        { value: "Cobrado", label: "Cobrado" },
        {
          value: "Não Será Cobrado (Fatura)",
          label: "Não Será Cobrado (Fatura)",
        },
        {
          value: "Não Será Cobrado (Nunca)",
          label: "Não Será Cobrado (Nunca)",
        },
      ];
    }

    this.displayedColumns = this.displayedColumns.concat(columns);
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();
  }

  async loadTable() {
    await this.observableRequest(this.getPaginationParams(0));
  }

  async observableRequest(page: string) {
    let fullUrl = this.baseUrl;

    fullUrl += page;

    fullUrl += `&pageSize=${this.pageSize}`;

    if (this.urlQueryParams) {
      fullUrl += this.urlQueryParams;
    }
    if (this.sort.active && !this.sortDefault) {
      fullUrl += `&orderBy=${
        this.sort.direction === "asc" || ""
          ? this.sort.active
          : "-" + this.sort.active
      }`;
    }

    setTimeout(async () => {
      this.loadingService.routeLoading(this.loadingSubjectName, true);
      let result = await this.baseRequests.getRequest(fullUrl);
      this.length =
        result.data.itemsCount !== undefined
          ? result.data.itemsCount
          : result.data.length;
      this.dataSource.data =
        result.data.itemsCount !== undefined
          ? result.data.listOfItems
          : result.data;
      this.loadingService.routeLoading(this.loadingSubjectName, false);
    }, 1000);
  }

  getPaginationParams(pageIndex: number) {
    let stringParam = "?pageNum=" + pageIndex;
    return stringParam;
  }

  getColumnParamProperty(columnName: string) {
    const allColumns = this.indexes.concat(this.specialColumnsIndexes);
    return allColumns.find((x) => x.label === columnName)?.property;
  }

  searchOrderFilterOrPaginate(pageEvent?: PageEvent) {
    const searchValue = this.formGroup.get("searchControl").value;

    this.pageSize = pageEvent.pageSize;

    const filterValue = this.getColumnParamProperty(
      this.formGroup.get("filterControl").value
    );

    if (!pageEvent) {
      this.matPaginator.pageIndex = 0;
    }

    return this.observableRequest(
      this.getPaginationParams(pageEvent ? pageEvent.pageIndex : 0)
    );
  }

  reorganizeColumns(reorganizedColumns: string[]) {
    reorganizedColumns.forEach((x) => {
      if (!this.displayedColumns.find((y) => y === x)) {
        throw new Error(
          `Column ${x} to be reorganized is not defined in Table Setup`
        );
      }
    });

    this.displayedColumns = reorganizedColumns;
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.showStatus = false;
      this.selectedRows = 0;
    } else {
      this.showStatus = true;
      this.dataSource.data.forEach((row) => {
        this.selection.select(row);
        this.selectedRows++;
      });
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  checkboxSelected(row: any) {
    this.selection.toggle(row);
    this.selection.isSelected(row) ? this.selectedRows++ : this.selectedRows--;
    this.selection.selected.length > 0
      ? (this.showStatus = true)
      : (this.showStatus = false);
  }

  changeStatus(status: string) {
    this.changed = true;
    return this.selection.selected.map((b) => (b.status = status));
  }

  onChangeSingleStatus() {
    this.changed = true;
  }

  changeStatusBatch(status: string) {
    const message = this.MESSAGE_STATUS_CHANGE;
    const title = this.TITLE_STATUS_CHANGE;

    const dialogRef = this.dialog.open(DialogChangeStatusComponent, {
      width: "525px",
      data: { message, title },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmation === true) {
        this.selection.clear();
        return this.generateNewVersion(status);
      }
    });
  }

  async revert() {
    this.selection.clear();
    this.changed = false;
    this.showStatus = false;
    this.selectedRows = 0;
    await this.observableRequest(this.getPaginationParams(0));
  }

  generateNewVersion(status: string = undefined) {
    let body = {
      invoiceId: this.idFatura,
      animals: this.selection.selected,
      status,
    };
    return this.http
      .post(`${API}/invoices/generateNewInvoiceVersion`, body)
      .pipe(
        debounceTime(500),
        map((response) => response)
      )
      .subscribe(async (b) => {
        this.changed = false;
        this.showStatus = false;
        this.selectedRows = 0;
        this.selection.clear();
        await this.observableRequest(this.getPaginationParams(0));
        this._api.emitChange();
      });
  }

  openDialogFilter(): void {
    const dialogRef = this.dialog.open(DialogAnimalListComponent, {
      width: "525px",
      data: this.filterObject,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.buildURLQueryParams(
        result.valueMin,
        result.valueMax,
        result.status,
        result.lastReadMin,
        result.lastReadMax,
        result.batch,
        result.pen,
        result.sisbov,
        result.boschNumber,
        result.parcel
      );
      this.loadTable();
    });
  }

  buildURLQueryParams(
    valueMin,
    valueMax,
    status,
    lastReadMin,
    lastReadMax,
    batch,
    pen,
    sisbov,
    boschNumber,
    parcel
  ) {
    this.urlQueryParams = "";
    if (lastReadMin && lastReadMin !== "") {
      const month =
        lastReadMin.getMonth() < 9
          ? "0" + (lastReadMin.getMonth() + 1)
          : lastReadMin.getMonth() + 1;
      const day =
        lastReadMin.getDate() < 10
          ? "0" + lastReadMin.getDate()
          : lastReadMin.getDate();
      const dateFormat = lastReadMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(
        `&lastReadDateMin=${dateFormat}`
      );
    }
    if (lastReadMax && lastReadMax !== "") {
      const month =
        lastReadMax.getMonth() < 9
          ? "0" + (lastReadMax.getMonth() + 1)
          : lastReadMax.getMonth() + 1;
      const day =
        lastReadMax.getDate() < 10
          ? "0" + lastReadMax.getDate()
          : lastReadMax.getDate();
      const dateFormat = lastReadMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(
        `&lastReadDateMax=${dateFormat}`
      );
    }
    if (status && status !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&status=${status}`);
    }
    if (valueMin && valueMin !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMin=${valueMin}`);
    }
    if (valueMax && valueMax !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMax=${valueMax}`);
    }
    if (batch && batch !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&batchName=${batch}`);
    }
    if (pen && pen !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&penName=${pen}`);
    }
    if (sisbov && sisbov !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&sisbov=${sisbov}`);
    }
    if (boschNumber && boschNumber !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(
        `&boschNumber=${boschNumber}`
      );
    }
    if (parcel && parcel !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&parcel=${parcel}`);
    }
  }

  async sortData(event: any) {
    if (this.sort.direction !== "") {
      this.sortDefault = false;
    } else {
      this.sortDefault = true;
    }
    this.showRows = true;
    await this.observableRequest(
      this.getPaginationParams(this.matPaginator.pageIndex)
    );
    this.showRows = false;
  }
}
