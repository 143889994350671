import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoadingService {
  billsAndContractsLoading: Subject<boolean> = new Subject();

  constructor() { }

  routeLoading(subjectName, trigger: boolean) {
    switch (subjectName) {
      case 'BillsAndContracts': case 'Invoice':
        this.billsAndContractsLoading.next(trigger);
        break;
    }
  }
}
