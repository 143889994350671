import { Component, OnInit, Inject, HostListener, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SvgIconService } from 'src/app/services/svg-icon.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogInvoiceValueEditionComponent } from 'src/app/invoice/dialog-invoice-value-edition/dialog-invoice-value-edition.component';
import { DialogInvoiceStateInvoiceInputComponent } from 'src/app/invoice/dialog-invoice-state-invoice-input/dialog-invoice-state-invoice-input.component';
import { DialogChangeStatusComponent } from 'src/app/invoice/dialog-change-status/dialog-change-status.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-overview-invoice',
  templateUrl: './overview-invoice.component.html',
  styleUrls: ['./overview-invoice.component.scss'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]

})
export class OverviewInvoiceComponent implements OnInit {
  @Output() isLoaded: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router,
    private iconService: SvgIconService,
    private _api: ApiService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) document,
    private _invoice: InvoiceService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
  }

  fatura: number;
  vlrFatura: string;
  dataFatura: string;
  servico: string;
  versao: string;
  prazoPagto: string;
  status: string;
  fazenda: string;
  cpfcnpj: string;
  tipo: string;
  vigencia: string;
  periodo: string;
  dtFaturamento: string;
  dtFechamento: string;
  dtAbertura: string;
  prazo: string;
  qtdAnimais: number;
  contratoVinculado: number;
  idContrato: string;
  id: string;
  invoice: any;
  nextStatus: string;
  lastStatus: string;
  labelAction: string;
  linkAction: boolean = false;
  statusLabel: string;
  chargedValue: number;
  chargedValueComment: string;
  chargedValueUser: string;
  stateInvoiceNumber: string;
  queryParam: string;

  ngOnInit(): void {
    this.queryParam = this.route.snapshot.params['id'];
    this.loadInvoiceComponent(this.queryParam);
    this._api.changeEmitted$.subscribe(() => {
      this.loadInvoiceComponent(this.queryParam);
    });
  }

  loadInvoiceComponent(queryParam: string) {
    if (!queryParam) { return; }

    return this._api.getInvoice(queryParam)
      .subscribe(response => {

        let dateStart = new Date(response.contractStartingDate);
        let startingDate = (dateStart.getDate()).toString().padStart(2, '0') + "/" + (dateStart.getMonth() + 1).toString().padStart(2, '0') + "/" + dateStart.getFullYear();
        let dateClose = new Date(response.contractEndingDate);
        let closingDate = (dateClose.getDate()).toString().padStart(2, '0') + "/" + (dateClose.getMonth() + 1).toString().padStart(2, '0') + "/" + dateClose.getFullYear();
        let date = new Date(new Date(response.invoiceDate).getTime() + (response.daysToTerm * 24 * 60 * 60 * 1000));
        let dtFatura = new Date(response.invoiceDate);
        let dtFaturamento = (dtFatura.getDate()).toString().padStart(2, '0') + "/" + (dtFatura.getMonth() + 1).toString().padStart(2, '0') + "/" + dtFatura.getFullYear();

        this.id = this.route.snapshot.params['id'];
        this.fatura = response.numInvoice;
        this.vlrFatura = response.TotalValue;

        this.dataFatura = dtFaturamento;
        this.servico = response.serviceType;
        this.versao = response.version;
        this.prazoPagto = response.daysToTerm;
        this.status = response.invoiceStatus;
        this.statusLabel = this.setStatusLabel(this.status);
        this.fazenda = response.farmName;
        this.cpfcnpj = response.farmDocument;
        this.tipo = response.contractType;
        this.vigencia = "De " + startingDate + " até " + closingDate;
        this.periodo = response.billingPeriod;
        this.dtFaturamento = dtFaturamento;
        this.dtFechamento = response.closingDate;
        this.prazo = response.daysToTerm + " dias (" + (date.getDate()).toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear() + ")";
        this.qtdAnimais = response.numberOfMonitoredAnimals;
        this.contratoVinculado = response.contractCode;
        this.idContrato = response.contractId;
        this.chargedValue = response.chargedValue;
        this.chargedValueComment = response.chargedValueComment;
        this.chargedValueUser = response.chargedValueUser;
        this.stateInvoiceNumber = response.stateInvoiceNumber;
        this.dtAbertura = response.openingDate;


        this._invoice.setIdFatura(this.id);
        this._invoice.setStatus(this.status);
        this.statusLabel = this.setStatusLabel(this.status);

        switch (this.status) {
          case 'à faturar':
            this.nextStatus = "em análise";
            this.lastStatus = "";
            this.labelAction = "Iniciar Faturamento";
            this.linkAction = true;
            break;
          case 'em análise':
            this.nextStatus = "aguardando nf";
            this.lastStatus = "à faturar";
            this.labelAction = "Concluir Análise";
            this.linkAction = true;
            break;
          case 'aguardando nf':
            this.nextStatus = "encerrado";
            this.lastStatus = "em análise";
            this.labelAction = "Encerrar Faturamento";
            this.linkAction = true;
            break;
          case 'encerrado':
            this.lastStatus = "aguardando nf";
            break;
          case 'cancelado':
            this.nextStatus = "regerar";
            this.labelAction = "Gerar Fatura Novamente";
            this.linkAction = true;
            break;
        }
        this.isLoaded.emit();
      });

  }

  export(id: string) {
    return this._api.export(id, this.fazenda, this.dtFaturamento, this.fatura);
  }

  exportToPDF(id: string) {
    return this._api.exportToPDF(id, this.fazenda, this.dtFaturamento, this.fatura);
  }

  getStatusChipColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#ffffff";
      default:
        return "#e6e6e6";
    }
  }

  getStatusBorderColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#e6e6e6";
    }
  }

  setStatusBorder(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "1px solid";
      default:
        return "none";
    }
  }

  getStatusTextColor(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#ffffff";
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // if (window.pageYOffset > 70) {
    //   let element = document.getElementById('row-overview');
    //   element.classList.add('sticky-overview');
    // } else {
    //   let element = document.getElementById('row-overview');
    //   element.classList.remove('sticky-overview');
    // }
  }

  changeStatus(id: string, nextStatus: string) {
    let message = "";
    let title = "";
    if (nextStatus == 'regerar') {
      message = "Deseja gerar essa fatura novamente?"
      title = "Gerar fatura"
    }
    const dialogRef = this.dialog.open(DialogChangeStatusComponent, {
      width: '525px',
      data: { message, title }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirmation === true) {
        this.confirmedChangeStatus(id, nextStatus);
      }
    });
  }

  confirmedChangeStatus(id: string, nextStatus: string) {
    if (nextStatus == 'regerar') {
      return this._api.regenerateInvoice(id)
        .subscribe(b => {
          this.toastr.success("A fatura foi gerada com sucesso");
          //${b.invoiceId}
          this.router.navigate([`/bills/`]);
          //window.location.reload();
          //console.log(b);
        },
          (error) => {
            console.log("Erro", error);
          });
    }

    if (nextStatus === "encerrado") {
      const dialogRef = this.dialog.open(DialogInvoiceStateInvoiceInputComponent, {
        width: '525px',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.stateInvoiceNumber) {
          return this._api.changeStatus(id, nextStatus, result.stateInvoiceNumber)
            .subscribe(b => {
              window.location.reload();
              //console.log(b);
            },
              (error) => {
                console.log("Erro", error);
              });
        }

      });
    } else {
      return this._api.changeStatus(id, nextStatus)
        .subscribe(b => {
          window.location.reload();
          //console.log(b);
        },
          (error) => {
            console.log("Erro", error);
          });
    }
  }

  setStatusLabel(status) {
    switch (status) {
      case "à faturar":
        return "A Faturar";
      case "aguardando nf":
        return "Aguardando NF";
      case "em análise":
        return "Em Análise";
      case "encerrado":
        return "Encerrado";
      case "cancelado":
        return "Cancelado";
      default:
        return "";
    }
  }

  openDialogValueEdition(): void {
    const dialogRef = this.dialog.open(DialogInvoiceValueEditionComponent, {
      width: '525px',
      data: { idInvoice: this.queryParam }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadInvoiceComponent(this.queryParam);
    });
  }

  canEditChargedValue(): boolean {
    let canEditChargedValue = false;
    if (this.status === "em análise" || this.status === "aguardando nf") {
      canEditChargedValue = true;
    }
    return canEditChargedValue;
  }
}
