<div mat-dialog-content>

    <label class="modal-title boschsansmedium blue mb-0"><b>Inserir Nota Fiscal</b></label>


    <form class="form-area" [formGroup]="formGroup">
        <div class="row mt-3 mb-5">
            <div class="col col-12">
                <span><b>Nota Fiscal*</b></span>
                <input class="form-control" formControlName="stateInvoiceNumber" placeholder="Nota Fiscal" />
            </div>
        </div>            
    </form>
</div>
<div mat-dialog-actions class="row">
    <div class="col-3 mt-5 button-area">
        <p (click)="onNoClick()">Cancelar</p> 
    </div>
    <div class="offset-4 col-3 mt-5 button-area">
        <button mat-button class="mat-focus-indicator button-style mat-flat-button mat-button-base"
            (click)="submit()" >
            Concluir
        </button>
    </div>
</div>