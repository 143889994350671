import { Component, OnInit, Inject } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  confirmation: boolean,
  message: string,
  title: string
}
@Component({
  selector: 'app-dialog-change-status',
  templateUrl: './dialog-change-status.component.html',
  styleUrls: ['./dialog-change-status.component.scss']
})
export class DialogChangeStatusComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  confirmation: boolean;
  message: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<DialogChangeStatusComponent>,
    private _api: ApiService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.confirmation = this.data.confirmation;
    if(this.data.message){
      this.message = this.data.message;
      this.title = this.data.title;
    }else{
      this.message = "Deseja realmente alterar o status da fatura?";
      this.title = "Confirmação da mudança de status";
    }
    
  }

  onNoClick(): void {
    this.data.confirmation = false;
    this.dialogRef.close(this.data);
  }

  clean(){

  }

  submit(){
    this.data.confirmation = true;
    this.dialogRef.close(this.data);
  }

}
