<div class="root-container sub-container">
    <div class="col-xl-8 offset-xl-2 col-10 offset-1">
        <section id="row-overview" (scroll)="onWindowScroll($event);">
            <div class="row">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Faturamentos</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Fatura #{{ fatura }}</li>
                    </ol>
                </nav>
            </div>
            <div class="row">
                <div class="col col-md-10 vg-label">
                    <label class="subtitle">Fatura <strong>#{{ fatura }}</strong></label>
                </div>
                <div class="col col-vg vg-label pr-md-4">
                    <mat-chip-list style="float: right;">
                        <mat-chip class="mat-chip-style" [style.color]="getStatusTextColor(status)"
                        [style.border-color]="getStatusBorderColor(status)"
                        [style.border]="setStatusBorder(status)"
                        [style.background-color]="getStatusChipColor(status)">
                            {{ statusLabel }}
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="row my-md-3">
                <div  class="col-3 offset md-3 link-vg px-md-0">
                    <a *ngIf="status !== 'cancelado' && status !== 'à faturar'" href="javascript:void(0);" class="blue" (click)="changeStatus(id, lastStatus)">
                        <img class="icon-img" src="../../../assets/undo.svg"/>
                        <span>Voltar para o status anterior </span>
                    </a>
                </div>  
                <div *ngIf="linkAction && nextStatus !== 'regerar'" class="col-2 link-vg px-md-0">
                    <a href="javascript:void(0);" class="red" (click)="changeStatus(id, 'cancelado')">
                        <img class="icon-img" src="../../../assets/minus-circle.svg"  />
                        <span>Cancelar Fatura</span>
                    </a>
                </div>
              
                <div class="col-2 link-vg pl-md-0 pr-md-4">
                    <a href="javascript:void(0);" class="blue" (click)="exportToPDF(id)">
                        <mat-icon class="img-icon" svgIcon="svg-external-link-square-alt"></mat-icon>
                        <span>Exportar PDF</span>
                    </a>
                </div>
                <div class="col-2 link-vg pl-md-0 pr-md-4">
                    <a href="javascript:void(0);" class="blue" (click)="export(id)">
                        <mat-icon class="img-icon" svgIcon="svg-external-link-square-alt"></mat-icon>
                        <span>Exportar .XLS</span>
                    </a>
                </div>
                <div *ngIf="linkAction" class="col-3 link-vg pl-md-0 pr-md-4">
                    <a href="javascript:void(0);" class="blue" (click)="changeStatus(id, nextStatus)">
                        <mat-icon class="img-icon" svgIcon="svg-receipt_2"></mat-icon>
                        <span>{{ labelAction }}</span>
                    </a>
                </div>
            </div>
        </section>
        <div class="bkg-vg">
            <div class="row details-vg">
                <div class="col col-vg vg-label border-0">
                    <span>VALOR DA FATURA</span> <br />
                    <span class="vg-p">{{ chargedValue | currency:'BRL':'symbol':'1.2-2':'br' }}</span> <br />
                    <span class="sbtxt-vg">Versão {{ versao }}</span>
                </div>
                <div class="col ml-md-2 col-vg vg-label">
                    <span>DATA DA FATURA</span> <br />
                    <span class="vg-p">{{ dataFatura }}</span> <br />
                    <span class="sbtxt-vg">Pgto. em {{ prazoPagto }} dias</span>
                </div>
                <div class="col ml-md-2 col-vg vg-label">
                    <span>SERVIÇO</span>
                    <p class="vg-p">{{ servico }}</p>
                </div>
            </div>
        </div>
        <div class="sub-vg">
            <div class="row pt-md-3">
                <div class="col ml-md-3 vg border-0">
                    <label class="blue"><strong>CONTRATUAL</strong></label>
                </div>
                <div class="col ml-md-2 vg border-0">
                    <label class="blue"><strong>DETALHES DA COBRANÇA</strong></label>
                </div>
                <div class="col ml-md-2">
                </div>
                <div class="col ml-md-2">
                </div>                
            </div>
            <div class="row">
                <div class="col ml-md-3 vg border-0">
                    <span><b>Fazenda</b></span> <br />
                    <span class="">{{ fazenda }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Período de Monitoramento</b></span> <br />
                    <span class="">{{ dtAbertura | date:'dd/MM/yyyy' }} à {{ dtFechamento | date:'dd/MM/yyyy' }} ({{ periodo | titlecase }})</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Contrato Vinculado</b></span>
                    <p class=""><a href="/contract/{{ idContrato }}">#{{ contratoVinculado }}</a></p>
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Valor Calculado</b></span>
                    <p class="">{{ vlrFatura | currency:'BRL':'symbol':'1.2-2':'br' }}</p>   
                </div>                
            </div>
            <div class="row">
                <div class="col ml-md-3 vg border-0">
                    <span><b>CNPJ / CPF</b></span> <br />
                    <span class="">{{ cpfcnpj }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Data de Faturamento</b></span> <br />
                    <span class="">{{ dtFaturamento }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Animais Monitorados</b></span>
                    <p class="">{{ qtdAnimais }}</p>
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span *ngIf="chargedValue >= 0"><b>Valor Cobrado</b></span> 
                    <p *ngIf="chargedValue >= 0" class="">{{ chargedValue | currency:'BRL':'symbol':'1.2-2':'br' }}</p>  
                </div>                
            </div>
            <div class="row">
                <div class="col ml-md-3 vg border-0">
                    <span><b>Tipo de Contrato</b></span> <br />
                    <span class="">{{ tipo }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">
                    <span><b>Prazo para Pagamento</b></span> <br />
                    <span class="">{{ prazo }}</span> <br />
                </div>
                <div class="col ml-md-2 vg border-0">  
                    <span *ngIf="stateInvoiceNumber"><b>Nota Fiscal</b></span> <br />
                    <span *ngIf="stateInvoiceNumber" class="">{{ stateInvoiceNumber }}</span> <br />                                  
                </div>
                <div class="col ml-md-2 vg border-0">
                </div>                
            </div>
            <div class="row">
                <div class="col ml-md-3 mt-3 vg border-0">
                    <span><b>Vigência</b></span> <br />
                    <span class="">{{ vigencia }}</span> <br />
                </div>
                <div class="col ml-md-2 mt-3 vg border-0">

                </div>
                <div class="col ml-md-2 mt-3 vg border-0">
                </div>
                <div class="col ml-md-2 mt-3 vg border-0">

                </div>                
            </div>
            <div class="col ml-md-3 vg border-0">
            <div *ngIf="chargedValueUser" class="mt-3">
                <div class="row">
                    <span><b>Autor da edição de valor</b></span><br />
                </div>
                <div class="row">
                    <span class="">{{ chargedValueUser }}</span>
                </div>    
            </div>
            <div *ngIf="chargedValueUser" class="mt-3">
                <div class="row">
                    <span><b>Comentário sobre valor editado</b></span><br />
                </div>
                <div class="row">
                    <div class="comments-container">
                        <span class="comments-content">{{ chargedValueComment }}</span>
                    </div>                    
                </div>
            </div>
        </div>
            <div class="row mt-md-3">
                <div class="col ml-md-3 vg border-0">
                    <div class="blue">
                        <label><a href="/contract/{{ idContrato }}?scrollToContacts=true">Ver informações de contato</a></label>
                    </div>
                </div>
                <div class="col ml-md-2">
                </div>
                <div class="col ml-md-2">
                </div>
                <div class="col ml-md-2 vg border-0">
                    <div class="blue" *ngIf="canEditChargedValue()">                     
                        <label><a href="javascript:void(0);" (click)="openDialogValueEdition()">Editar valor cobrado</a></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>