import { Component, OnInit, ViewChild } from '@angular/core';
import { TableIndex } from 'src/app/domain/interfaces/table-index.interface';
import { API } from 'src/app/shared/app.api';
import { BaseTableContractComponent } from 'src/app/shared/base-table-contract/base-table-contract.component';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {

  @ViewChild(BaseTableContractComponent, { static: true })
  baseTable: BaseTableContractComponent;

  numContract: string;

  indexes: TableIndex[] = [
    { label: "FATURAMENTO", property: "numInvoice" }
  ];

  specialColumnsIndexes: TableIndex[] = [
    { label: "DATA", property: "invoiceDate" },
    { label: "FECHAMENTO", property: "invoiceClosingDate" },
    { label: "VALOR CALCULADO", property: "invoiceValue" },
    { label: "VALOR COBRADO", property: "invoiceChargedValue" },
    { label: "STATUS", property: "invoiceStatusEnum" },
    { label: "SERVIÇO", property: "invoiceTypeEnum" }
  ];

  constructor(private _invoice: InvoiceService,private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._invoice.numContract.subscribe(b => {
      this.numContract = b;
      this.baseTable.setupTable(
        `${API}/invoices`,
        this.indexes,
        'BillsAndContracts',
        this.numContract,
        this.specialColumnsIndexes
      );
  
      this.baseTable.reorganizeColumns([
        "numInvoice",
        "invoiceClosingDate",
        "invoiceDate",
        "invoiceValue",
        "invoiceChargedValue",        
        "invoiceTypeEnum",
        "invoiceStatusEnum",
      ]);
  
      this.baseTable.loadTable();      
    });
  }

}
