<div class="base-table-container container-fluid">
    <div class="row header-labels">
        <div class="row col-12 pr-0">
            <div class="col-4 no-padding">
                <label class="grid-title" *ngIf="grid==='Animals'">LISTA DE ANIMAIS</label>
            </div>
            <div class="offset-5 col-3 button-area">
                <a href="javascript:void(0);" class="blue boschsansregular" (click)="openDialogFilter()" *ngIf="grid==='Animals'">
                    <img class="icon-medium pr-2" src="../../../assets/filter.png" />
                    <span>Filtros</span>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="showStatus && !status" class="row">
        <div class="link-status" style="width: 16%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatus('Cobrado')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>A Ser Cobrado</span>
            </a>
        </div>
        <div class="col link-status" style="width: 21%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatus('Não Será Cobrado (Fatura)')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>Não Será Cobrado (Fatura)</span>
            </a>
        </div>
        <div class="col link-status" style="width: 21%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatus('Não Será Cobrado (Nunca)')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>Não Será Cobrado (Nunca)</span>
            </a>
        </div>
        <div class="col offset-2 px-md-0" style="text-align: right;">
        </div>
    </div>
    <div *ngIf="showStatus && !status" class="row">
        <div class="link-status" style="width: 16%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatusBatch('Cobrado')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>A Ser Cobrado (Fatura inteira)</span>
            </a>
        </div>
        <div class="col link-status" style="width: 21%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatusBatch('Não Será Cobrado (Fatura)')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>Não Será Cobrado (Fatura) (Fatura inteira)</span>
            </a>
        </div>
        <div class="col link-status" style="width: 21%;">
            <a href="javascript:void(0);" class="blue" (click)="changeStatusBatch('Não Será Cobrado (Nunca)')">
                <img class="icon-img" src="../../../assets/Component 48 – 1.svg" />
                <span>Não Será Cobrado (Nunca) (Fatura inteira)</span>
            </a>
        </div>
        <div class="col offset-2 px-md-0" style="text-align: right;">
            <label>{{ selectedRows }} animais selecionados</label>
        </div>
    </div>
    <div class="row">
        <table (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="base-table mat-elevation-z8">
            <ng-container matColumnDef="select">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? checkboxSelected(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container *ngFor="let index of indexes" matColumnDef="{{ index.property }}">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> {{ index.label }} </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element[index.property] }}
                </td>
            </ng-container>

            <ng-container matColumnDef="lastRead">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> ÚLTIMA LEITURA </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.lastRead | date:'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> DATA </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date:'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> VALOR </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.value | currency:'BRL':'symbol':'1.2-2':'br' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="parcel">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> PARCELA </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.parcel }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> STATUS </b>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field *ngIf="listStatus.length > 0 && !status">
                        <mat-select [(value)]="element.status" (selectionChange)="onChangeSingleStatus()">
                            <mat-option *ngFor="let status of listStatus" [value]="status.value">
                                {{ status.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <label *ngIf="listStatus.length === 0 || status">{{ element.status | titlecase }}</label>
                </td>
            </ng-container>

            <ng-container matColumnDef="observation">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef mat-sort-header>
                    <b> OBSERVAÇÃO </b>
                </th>

                    <td mat-cell *matCellDef="let element" class="text-capitalize observation-column">
                        {{ element.observation }}
                    </td>

            </ng-container>

            <ng-container matColumnDef="missing">
                <th class="header-margin-bottom" mat-header-cell *matHeaderCellDef>
                    <b> </b>
                </th>
                <td mat-cell *matCellDef="let element" class="warn-img">
                    <mat-icon *ngIf="element.missing" class="icon-img warn-img" matTooltipPosition="above"
                        svgIcon="svg-exclamation-circle" matTooltip="Animal Ausente" matTooltipClass="custom-tooltip">
                    </mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr [ngClass]="{'hide-rows': this.showRows}" mat-row *matRowDef="let row; columns: displayedColumns" class="order-by-label example-element-row"
                (click)="checkboxSelected(row)"></tr>
        </table>
    </div>
    <div class="row">
        <mat-paginator class="paginator-style" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"
            (page)="searchOrderFilterOrPaginate($event)">
        </mat-paginator>
    </div>
</div>
<div *ngIf="changed && !status" class="modal-save col-12">
    <div style="width: 66%;" class="offset-2">
        <div class="row">
            <div class="col-6 align-self-center">
                <span><b>A fatura contém alterações.</b></span>
            </div>
            <div class="col-3">
                <div class="button-area">
                    <button class="alt-button-style" mat-flat-button (click)="revert()">
                        Cancelar
                    </button>
                </div>
            </div>
            <div class="col-3">
                <div class="button-area">
                    <button class="button-style" mat-flat-button (click)="generateNewVersion()">
                        Salvar (Nova Versão)
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
