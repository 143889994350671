import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { InvoiceService } from 'src/app/services/invoice.service';
import { Router } from '@angular/router';
import { LoadingService } from '../loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableIndex } from 'src/app/domain/interfaces/table-index.interface';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';
import { tap, delay, map, finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogBillContractListComponent } from 'src/app/contract/dialog-bill-contract-list/dialog-bill-contract-list.component';
import { BaseRequests } from '../base-requests';

@Component({
  selector: 'app-base-table-contract',
  templateUrl: './base-table-contract.component.html',
  styleUrls: ['./base-table-contract.component.scss']
})
export class BaseTableContractComponent implements OnInit {

  @ViewChild(MatPaginator, {}) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public showRows: boolean = false;
  public sortDefault: boolean = false;
  urlQueryParams: string = "";
  filterObject = {
    aFaturar: false,
    emAnalise: false,
    aguardandoNF: false,
    encerrada: false,
    cancelada: false,
    dateMin: "",
    dateMax: "",
    valueMin: "",
    valueMax: "",
    code: "",
    farm: "",
  };
  indexes: TableIndex[] = [];
  specialColumnsIndexes: TableIndex[] = [];

  displayedColumns: string[] = [];

  orderByOptions: string[] = [undefined];
  filterOptions: string[] = [undefined];

  loadingSubjectName: string;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  formGroup: FormGroup = new FormGroup({
    searchControl: new FormControl(""),
    orderControl: new FormControl(""),
    filterControl: new FormControl("")
  });

  baseUrl: string;
  component: string
  offset = 0;
  limit = 10;
  numContract: string;

  // MatPaginator Inputs
  length = 0;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private http: HttpClient,
    private updateRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private router: Router,
    public dialog: MatDialog,
    private baseRequests: BaseRequests
  ) { }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (sortHeaderId === 'invoiceStatusEnum') {
        return;
      }
      const value: any = data[sortHeaderId];
      return typeof value === "string" ? value.toLowerCase() : value;
    };
  }

  ngOnDestroy() {
  }

  setupTable(
    baseUrl: string,
    indexes: TableIndex[],
    loadingSubjectName: string,
    numContract: string,
    specialColumnsIndexes?: TableIndex[]
  ) {
    this.baseUrl = baseUrl;
    this.indexes = indexes;
    this.numContract = numContract;
    this.loadingSubjectName = loadingSubjectName;

    let columns = [];
    let columnsFilter = [];
    columns = this.indexes.map(x => x.property);
    columnsFilter = this.indexes.map(x => x.label);

    if (specialColumnsIndexes) {
      this.specialColumnsIndexes = specialColumnsIndexes;
      columns = columns.concat(specialColumnsIndexes.map(x => x.property));
      columnsFilter = columnsFilter.concat(specialColumnsIndexes.map(x => x.label));
    }

    columnsFilter.forEach(x => {
      this.filterOptions.push(x);
    });

    this.displayedColumns = columns;
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();
    this.loadTable();
  }

  async loadTable() {
    await this.observableRequest(this.getPaginationParams(0));
  }

  async observableRequest(
    page: string,
  ) {
    let fullUrl = this.baseUrl;

    if (this.urlQueryParams && this.urlQueryParams !== "") {
      fullUrl += page + this.urlQueryParams + "&contractNumber=" + this.numContract;
    } else {
      fullUrl += page + "&contractNumber=" + this.numContract;
    }

    fullUrl += `&pageSize=${this.pageSize}`;

    if (this.sort.active && !this.sortDefault) {
      fullUrl += `&orderBy=${this.sort.direction === 'asc' || '' ? this.sort.active : '-' + this.sort.active}`;
    }

    setTimeout(async () => {
      this.loadingService.routeLoading(this.loadingSubjectName, true);
      let result = await this.baseRequests.getRequest(fullUrl);
      this.length = result.data !== undefined ? result.data.itemsCount : result.count
      this.dataSource.data = result.data !== undefined ?
        result.data.listOfItems : result.results;
      this.loadingService.routeLoading(this.loadingSubjectName, false);
    }, 1000);
  }

  getPaginationParams(pageIndex: number) {
    let stringParam = "?pageNum=" + pageIndex;
    return stringParam;
  }

  getColumnParamProperty(columnName: string) {
    const allColumns = this.indexes.concat(this.specialColumnsIndexes);
    return allColumns.find(x => x.label === columnName)?.property;
  }

  searchOrderFilterOrPaginate(pageEvent?: PageEvent) {
    if (!pageEvent) {
      this.matPaginator.pageIndex = 0;
    }

    this.pageSize = pageEvent.pageSize;

    return this.observableRequest(
      this.getPaginationParams(pageEvent ? pageEvent.pageIndex : 0)
    );
  }

  reorganizeColumns(reorganizedColumns: string[]) {
    reorganizedColumns.forEach(x => {
      if (!this.displayedColumns.find(y => y === x)) {
        throw new Error(
          `Column ${x} to be reorganized is not defined in Table Setup`
        );
      }
    });

    this.displayedColumns = reorganizedColumns;
    this.dataSource.sort = this.sort;
    this.updateRef.detectChanges();
  }

  getStatusChipColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#ffffff";
      default:
        return "#e6e6e6";
    }
  }
  getStatusBorderColor(billStatus: string) {
    switch (billStatus) {
      case "à faturar":
        return "#da2228";
      case "aguardando nf":
        return "green";
      case "em análise":
        return "#654199";
      case "encerrado":
        return "#e6e6e6";
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#e6e6e6";
    }
  }

  getStatusTextColor(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "#a9a9a9";
      default:
        return "#ffffff";
    }
  }

  setStatusBorder(billStatus: string) {
    switch (billStatus) {
      case "cancelado":
        return "1px solid";
      default:
        return "none";
    }
  }

  openDialogFilter(): void {
    const dialogRef = this.dialog.open(DialogBillContractListComponent, {
      width: '525px',
      data: this.filterObject
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filterObject.aFaturar = result.aFaturar;
        this.filterObject.emAnalise = result.emAnalise;
        this.filterObject.aguardandoNF = result.aguardandoNF;
        this.filterObject.encerrada = result.encerrada;
        this.filterObject.cancelada = result.cancelada;
        this.filterObject.dateMin = result.dateMin;
        this.filterObject.dateMax = result.dateMax;
        this.filterObject.valueMin = result.valueMin;
        this.filterObject.valueMax = result.valueMax;
        this.filterObject.code = result.code;
        this.filterObject.farm = result.farm;
        this.buildURLQueryParams(result.status, result.code, result.valueMin, result.valueMax, result.dateMin, result.dateMax, result.farm);
        this.loadTable();
      }
    });
  }

  buildURLQueryParams(status, code, valueMin, valueMax, dateMin, dateMax, farm) {
    this.urlQueryParams = "";
    if (status && status !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&status=${status}`);
    }
    if (code && code !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&code=${code}`);
    }
    if (valueMin && valueMin !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMin=${valueMin}`);
    }
    if (valueMax && valueMax !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&valueMax=${valueMax}`);
    }
    if (dateMin && dateMin !== "") {
      const month = dateMin.getMonth() < 9 ? "0" + (dateMin.getMonth() + 1) : (dateMin.getMonth() + 1);
      const day = dateMin.getDate() < 10 ? "0" + dateMin.getDate() : dateMin.getDate();
      const dateFormat = dateMin.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&dateMin=${dateFormat}`);
    }
    if (dateMax && dateMax !== "") {
      const month = dateMax.getMonth() < 9 ? "0" + (dateMax.getMonth() + 1) : (dateMax.getMonth() + 1);
      const day = dateMax.getDate() < 10 ? "0" + dateMax.getDate() : dateMax.getDate();
      const dateFormat = dateMax.getFullYear() + "-" + month + "-" + day;
      this.urlQueryParams = this.urlQueryParams.concat(`&dateMax=${dateFormat}`);
    }
    if (farm && farm !== "") {
      this.urlQueryParams = this.urlQueryParams.concat(`&farm=${farm}`);
    }
  }

  async sortData(event: any) {
    if (this.sort.direction !== '') {
      this.sortDefault = false;
    }
    else {
      this.sortDefault = true;
    }
    this.showRows = true;
    await this.observableRequest(this.getPaginationParams(this.matPaginator.pageIndex))
    this.showRows = false;
  }

  details(row: any) {
    //console.log(row)
    return this.router.navigate([`invoice/${row._id}`]);
  }
}
