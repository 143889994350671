import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  @Input()
  isContractRegisterParent: boolean;

  @Input()
  paramFarmId: string;

  @Output()
  servicesUpdate = new EventEmitter();

  editionMode: string = "hidden";

  lstServices: any[] = [];
  listLinkedSystems: any[] = [];
  erroBillDate: boolean = false;
  erroPrazoDate: boolean = false;
  expanded: boolean = false;
  lblbuton: string = "Adicionar Serviço";
  lblChannel: string = "Novo Serviço";
  idContract: string;
  idFarm: string;
  isPerformance: boolean = true;
  isHeadPerCycle: boolean = false;
  allPensSelected: boolean = false;

  formGroup: FormGroup = new FormGroup({
    _id: new FormControl(""),
    serviceType: new FormControl(""),
    linkedSystems: new FormControl(""),
    dtInicio: new FormControl(""),
    dtEnd: new FormControl(""),
    endless: new FormControl(false),
    periodo: new FormControl(""),
    billDate: new FormControl(""),
    prazo: new FormControl(""),
    periodoBonus: new FormControl(""),
    ativo: new FormControl(true),
    cobranca: new FormControl(true),
    value: new FormControl(""),
    chargeOnCycleEnd: new FormControl(false),
    cobrarPor: new FormControl(""),
    cicle: new FormControl(""),
    observacao: new FormControl(""),
    contractNumber: new FormControl(""),
  });

  constructor(
    private _api: ApiService,
    private _invoice: InvoiceService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.loadServices();
    this.idFarm = this.paramFarmId;
  }

  ngAfterViewInit() {
    this.loadFarms();
  }

  ngOnChanges() {
    this.clearFormControl();
    this.lstServices = [];
    this.idFarm = this.paramFarmId;
  }

loadFarms() {
  this._invoice.idFarm.subscribe(b => {
    if(b){
      this.idFarm = b;
    } 
    this.listLinkedSystems = [];
    if(this.idFarm && this.idFarm !== ""){
      this._api.getPens(this.idFarm)
      .subscribe((response: any) => {
        response.map((item, index) => {
          this.listLinkedSystems.push({ name: item.name, _id: item._id })
        });
      });
    }

  });
}
loadServices() {
  if(!this.isContractRegisterParent){
    this.lstServices = [];
    this._invoice.idContract.subscribe(b => {
      if(b){
        this.idContract = b;
        this._api.getServices(this.idContract)
        .subscribe((response: any) => {
          response.map((item, index) => {
    
            this.lstServices.push({
              type: item.type,
              penIdList: item.penIdList, //baia.slice(0, baia.length - 2),
              billingPeriod: item.billingPeriod,
              billingType: item.billingType,
              active: item.active,
              endingDate: item.endingDate,
              startingDate: item.startingDate,
              chargeValue: item.chargeValue,
              chargeOnCycleEnd: item.chargeOnCycleEnd,
              numberOfMonthsOfCycle: item.numberOfMonthsOfCycle,
              lastGeneratedInvoiceDate: item.lastGeneratedInvoiceDate,
              paymentTerm: item.paymentTerm,
              billingDay: item.billingDay,
              contractId: item.contractId,
              farmId: item.farmId,
              _id: item._id,
              bonusPeriodInMonths: item.bonusPeriodInMonths,
              animalFullChargeOnExit: item.animalFullChargeOnExit,
              contractNumber: item.contractNumber
            });
          })
    
        });
      }

    });
  }
}

  saveService() {
    let Ids = [];
    let baia = this.formGroup.get("linkedSystems").value;

    if( this.erroPrazoDate === true || this.erroBillDate === true ){
      this.toastr.error("Há campos preenchidos incorretamente.");
      return;
    }    
    
    if(!baia && this.isPerformance === true){
      this.toastr.error("Campos obrigatórios não foram preenchidos.");
      return;
    } else if(baia && this.isPerformance === true){
      baia.map((item, index) => {
        Ids.push({
          _id: item
        });
      });
    }

    if(this.isContractRegisterParent){
      Ids = [];
      Ids = this.listLinkedSystems.filter(system => baia.includes(system._id));
    }


    let body = {
      farmId: this.idFarm,
      type: this.formGroup.get("serviceType").value,
      penIdList: Ids,
      startingDate: this.formGroup.get("dtInicio").value,
      billingPeriod: this.formGroup.get("periodo").value,
      billingDay: this.formGroup.get("billDate").value ? Number(this.formGroup.get("billDate").value):0,
      paymentTerm: this.formGroup.get("prazo").value ? Number(this.formGroup.get("prazo").value):0,
      bonusPeriodInMonths: this.formGroup.get("periodoBonus").value ? Number(this.formGroup.get("periodoBonus").value):0,
      active: this.formGroup.get("ativo").value,
      animalFullChargeOnExit: this.formGroup.get("cobranca").value,
      chargeValue: this.formGroup.get("value").value,
      chargeOnCycleEnd: this.formGroup.get("chargeOnCycleEnd").value === 'true'? true: false,
      billingType: this.formGroup.get("cobrarPor").value,
      numberOfMonthsOfCycle: this.formGroup.get("cicle").value ? Number(this.formGroup.get("cicle").value):0,
      contractId: this.idContract,
      contractNumber: this.formGroup.get("contractNumber").value
    };

    if (!this.formGroup.get("endless").value) {
      body['endingDate'] = this.formGroup.get("dtEnd").value;
    }
    if(body.farmId === "" || body.type === "" || body.startingDate === "" || body.billingPeriod === "" || body.billingDay === 0 || body.paymentTerm === 0 ){
      this.toastr.error("Campos obrigatórios não foram preenchidos.");
      return;
    }
    if(this.isPerformance === true) {
      delete body.contractNumber;
      if(body.penIdList.length === 0 || !body.chargeValue || body.billingType === "" ){
        this.toastr.error("Campos obrigatórios não foram preenchidos.");
        return;
      }
      if(this.isHeadPerCycle === true) {
        if( body.numberOfMonthsOfCycle === 0 ){
          this.toastr.error("Campos obrigatórios não foram preenchidos.");
          return;
        }        
      } else {
        delete body.bonusPeriodInMonths;
        delete body.numberOfMonthsOfCycle;
      }
    } else {
      delete body.billingType;
      delete body.bonusPeriodInMonths;
      delete body.numberOfMonthsOfCycle;
      delete body.penIdList;
      if(body.contractNumber === "" || !body.chargeValue){
        this.toastr.error("Campos obrigatórios não foram preenchidos.");
        return;
      }             
    }

    if (!this.formGroup.get("_id").value)
      this.addService(body);
    else
      this.updateService(body);
    this.expanded = false;
  }

  addService(body: any) {
    if(!this.isContractRegisterParent){
    this._api.addService(this.idContract, body)
      .subscribe(response => {
        this.clearFormControl();
        this.loadServices();
        this._invoice.setpenChange(true);
      });
    } else {
      body.index = this.lstServices.length;
      this.lstServices.push(body);
      this.servicesUpdate.emit(this.lstServices);
      this.clearFormControl();
    }

  }

  updateService(body: any) {
    body._id = this.formGroup.get("_id").value,

    this._api.updateService(this.idContract, body)
      .subscribe(response => {
        this.clearFormControl();
        this.loadServices();
        this._invoice.setpenChange(true);
      });

    this.lblChannel = "Adicionar Serviço";
    this.lblbuton = "Novo Serviço";

  }

  edit(service: any, el: HTMLElement) {
    let baia = [];
    service.penIdList.map((item2, index2) => {
      baia.push(item2._id);
    });

    this.isPerformance = service.type === "Performance";
    this.isHeadPerCycle = service.billingType === "HeadPerCycle" && service.type === "Performance";
    this.expanded = true;
    this.formGroup.patchValue({
      _id: service._id,
      serviceType: service.type,
      linkedSystems: baia,
      dtInicio: service.startingDate,
      dtEnd: service.endingDate,
      endless: service.endless,
      periodo: service.billingPeriod,
      billDate: service.billingDay,
      prazo: service.paymentTerm,
      periodoBonus: service.bonusPeriodInMonths,
      ativo: service.active,
      cobranca: service.animalFullChargeOnExit,
      value: service.chargeValue,
      chargeOnCycleEnd: service.chargeOnCycleEnd.toString(),
      cobrarPor: service.billingType,
      cicle: service.numberOfMonthsOfCycle,
      observacao: service.observacao,
      contractNumber: service.contractNumber,
    });

    this.lblbuton = "Salvar Serviço (nova vesão)";
    this.lblChannel = "Editar Serviço";
    el.scrollIntoView({ behavior: "smooth" });
    this.toggleEditionMode("visible");
  }

  exclude(serviceIndex: any){
    this.lstServices = this.lstServices.filter(service => service.index !== serviceIndex)
  }

  changingType() {
    this.isPerformance = this.formGroup.get("serviceType").value === "Performance";
  }

  changingChargingType() {
    this.isHeadPerCycle = this.formGroup.get("cobrarPor").value === "HeadPerCycle";
  }

  cancelEdition(){
    this.expanded = false;
    this.clearFormControl();
    this.erroBillDate = false;
    this.erroPrazoDate = false;
    this.toggleEditionMode("hidden");
  }

  toggleEditionMode(mode: string){
    this.editionMode = mode;
    if(mode === "visible"){
      this.lblbuton = "Salvar Serviço (nova versão)";
      this.lblChannel = "Editar Serviço";
    } else {
      this.lblChannel = "Novo Serviço";
      this.lblbuton = "Adicionar Serviço";
    }
  }

  checkBillDateInput(){
    const forbidden = this.formGroup.value.billDate < 1 || this.formGroup.value.billDate > 31;
    this.erroBillDate = forbidden;
  }

  checkPrazoDateInput(){
    const forbidden = this.formGroup.value.prazo < 1 || this.formGroup.value.prazo > 31;
    this.erroPrazoDate = forbidden;
  }

  clearFormControl(){
    this.formGroup = new FormGroup({
      _id: new FormControl(""),
      serviceType: new FormControl(""),
      linkedSystems: new FormControl(""),
      dtInicio: new FormControl(""),
      dtEnd: new FormControl(""),
      endless: new FormControl(false),
      periodo: new FormControl(""),
      billDate: new FormControl(""),
      prazo: new FormControl(""),
      periodoBonus: new FormControl(""),
      ativo: new FormControl(true),
      cobranca: new FormControl(true),
      value: new FormControl(""),
      chargeOnCycleEnd: new FormControl(false),
      cobrarPor: new FormControl(""),
      cicle: new FormControl(""),
      observacao: new FormControl(""),
      contractNumber: new FormControl(""),
    });    
  }

  selectAllSystems(){
    this.allPensSelected = !this.allPensSelected;
    let allSystemsIds = [];
    if(this.allPensSelected === true){
      allSystemsIds = this.listLinkedSystems.map(system => system._id);
    }
    this.formGroup.get("linkedSystems").setValue(allSystemsIds); 
  }

}
