<div *ngFor="let comment of this.commentList">
    <div class="comments-list">
        <div class="comments-header">
            <p>{{comment.title}}</p>
            <div (click)="this.getCommentUpdate(comment)" class="comments-edit">
                <img src="../../../assets/edit.svg" />
                <p>Editar</p>
            </div>
        </div>
        <div class="comments-content">
            <p>{{comment.content}}</p>
        </div>
    </div>
</div>
<div class="comments-new-modal" *ngIf="newComment">
    <div class="comments-header">
        <p>Novo Comentario</p>
        <div class="comments-close">
            <!--Colocar botão de Close-->
        </div>
    </div>
    <form [formGroup]="comment" #ngForm="ngForm" (ngSubmit)="this.comment.valid && !isUpdate? handleNewComment():handleUpdateComment()">
        <span>Título do comentario</span>
        <input formControlName="title" id="title" placeholder="Título" 
        [ngClass]="{'error': (this.comment.controls.title.touched || this.comment.controls.title.dirty || ngForm.submitted) && !this.comment.controls.title.valid}" />
        <span>Comentário</span>
        <textarea formControlName="content" ic="content" placeholder="Digite ou cole o número da nota"
        [ngClass]="{'error': (this.comment.controls.content.touched || this.comment.controls.content.dirty || ngForm.submitted) && !this.comment.controls.content.valid}" ></textarea>
        <div class="comments-new-buttons-area">
            <p (click)="this.handleDeleteComment()">Excluir comentário</p>
            <div class="comments-new-buttons">
                <button (click)="hideNewComment()" class="cancel" id="cancel" type="button">Cancelar</button>
                <button class="insert" id="insert" type="submit">{{!isUpdate? 'Inserir':'Atualizar'}}</button>
            </div>
        </div>
    </form>
</div>
<div class="comments-new">
    <p (click)="this.showNewComment()">Inserir novo comentário</p>
</div>